import { Typography } from '@material-ui/core'
import { VideoType } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { validateVideoDistribution } from '../../firebase/firestore/content'
import { ContentInterface } from '../../types/content'
import { isContentVideo } from '../../utils/content.utils'
import { getTranslation } from '../../utils/translate.utils'
import { Header } from '../common'
import { SwitchWithLoading } from '../common/switchWithLoading'
import { useStore } from '../hooks/dataHooks/useStore'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'


interface Props {
    content: ContentInterface
}

export const ContentDistributions = observer(({ content }: Props) => {
    const [t] = useTranslation()
    const { store } = useStore()
    const { confirmAction } = useConfirmAction()
    const { showError } = useAlert()

    const dvtvExtraTag = store.globalDistributionTags.find(tag => tag.id === process.env.REACT_APP_DVTV_EXTRA_TAG_ID)

    const isVideo = isContentVideo(content)

    let isContentDvtvExtra = false
    let isContentVirtualProgram = false
    let hasContentProductPlacement = false

    if (isVideo) {
        if (dvtvExtraTag !== undefined) {
            isContentDvtvExtra = content.hasTagInLinkedVideos(dvtvExtraTag)
        }

        isContentVirtualProgram = content.getType === VideoType.VIRTUAL_PROGRAM
        hasContentProductPlacement = content.hasProductPlacement()
    }

    const handleToggleGlobalDistributionTag = async (id: string, isActive: boolean) => {
        const tag = store.globalDistributionTags.find((tag) => tag.id === id)
        
        if (tag) {
            await content.toggleDistribution(tag, isActive)
        }
    }

    const isGlobalDistributionTagControlDisabled = (id: string): boolean => {
        switch (id) {
            case process.env.REACT_APP_DVTV_EXTRA_TAG_ID:
                return isContentDvtvExtra
            default:
                return false
        }
    }

    const displayProductPlacementConfirmAction = async () => {
        if (!hasContentProductPlacement && isVideo) {
            try {
                await validateVideoDistribution(content)

                confirmAction(
                    async () => await content.setProductPlacement(),
                    t('Would you like to mark your video as containing product placement? Beware: this action is irreversable.'),
                    t('Product placement notice'),
                    t('Yes'),
                    t('No'),
                )
            } catch (e) {
                showError(e.errors.join(' '))
            }
        }
    }

    return (
        <>
            <Header title={t('Distributions')} />
            <SwitchWithLoading
                label={t('Show in feed')}
                isChecked={content.feedVisible}
                onCheck={async (isActive) => {
                    await content.setIsFeedVisible(isActive)
                }}
            />

            {!isContentVirtualProgram && (
                <SwitchWithLoading
                    label={t('Show in RSS')}
                    isChecked={content.shownInRss}
                    onCheck={async (isActive) => {
                        await content.setShowInRss(isActive)
                    }}
                />
            )}

            {store.globalDistributionTags.map((tag) => (
                <SwitchWithLoading
                    key={tag.id}
                    label={getTranslation(tag.name)}
                    isChecked={content.hasDistribution(tag)}
                    isDisabled={isGlobalDistributionTagControlDisabled(tag.id)}
                    onCheck={async (isActive) => {
                        switch (tag.id) {
                            case process.env.REACT_APP_DVTV_EXTRA_TAG_ID:
                                await displayProductPlacementConfirmAction()
                                break
                            default:
                                break
                        }

                        await handleToggleGlobalDistributionTag(tag.id, isActive)
                    }}
                />
            ))}

            <SwitchWithLoading
                label={t('Product placement')}
                isChecked={hasContentProductPlacement}
                isDisabled={hasContentProductPlacement || isContentDvtvExtra}
                onCheck={async () => await displayProductPlacementConfirmAction()}
            />

            {hasContentProductPlacement && (
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    {t('Video has been marked as containing product placement, which cannot be changed.')}
                </Typography>
            )}

            {(!hasContentProductPlacement && isContentDvtvExtra) && (
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    {t('Video has been marked as not containing product placement, which cannot be changed.')}
                </Typography>
            )}
        </>
    )
})
