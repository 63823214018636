import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import dayjs from 'dayjs'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Monetization from '../../../store/Monetization'
import { useOrganization } from '../../hooks'

import { CreatePurchaseForm } from './types'
import { getDayJsUnitFromFrequency } from './util'

import type { FormikProps } from 'formik'


type Props = {
    formik: FormikProps<CreatePurchaseForm>
}

export const MonetizationAutocomplete = memo((props: Props) => {
    const { formik } = props
    const { organization } = useOrganization()
    const [t] = useTranslation()

    const monetizations = organization?.monetizations?.filter(monetization =>
        monetization.getType === 'subscription' || monetization.getType === 'transaction')
    const fieldProps = formik.getFieldProps('monetization')

    return (
        <Autocomplete
            id="monetization-input"
            options={monetizations ?? []}
            getOptionLabel={m => m?.getName}
            value={fieldProps.value}
            onChange={(event, value: Monetization) => {
                formik.setFieldValue('monetization', value)
                formik.setFieldValue('video', null)
                if (value?.getType === 'subscription') {
                    const { count, unit } = getDayJsUnitFromFrequency(value)
                    formik.setFieldValue('endDate', dayjs(formik.values.startDate).add(count, unit).toDate())
                }
            }}
            onBlur={() => {
                formik.setFieldTouched('monetization')
            }}
            renderInput={(params) => <TextField
                {...params}
                label={t('Monetization')}
                variant="outlined"
                error={formik.touched.monetization && Boolean(formik.errors.monetization)}
                helperText={formik.touched.monetization && formik.errors.monetization}
            />}
        />
    )
})
