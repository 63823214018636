import { Logger } from '@tivio/common'
import { ContentDocument, Timestamp } from '@tivio/firebase'
import { ContentAvailability } from '@tivio/types'
import firebase from 'firebase'
import i18n from 'i18next'
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx'

import { alertError } from '../../utils/alert.utils'


export abstract class ContentBase<TDocument extends Omit<ContentDocument, 'type'>> {
    get ref() {
        return this._ref
    }

    get created(): Timestamp {
        return this.data.created
    }

    get createdDate(): Date {
        return this.created.toDate()
    }

    get availability(): ContentAvailability | undefined {
        if (this.data.availability) {
            return {
                from: this.data.availability.from.toDate(),
                to: this.data.availability.to ? this.data.availability.to.toDate() : null,
                manual: this.data.availability.manual || false,
            }
        }
        return
    }

    protected constructor(
        protected _ref: firebase.firestore.DocumentReference<TDocument>,
        protected data: TDocument,
        protected logger: Logger,
    ) {
        makeObservable<this, '_ref' | 'data'>(this, {
            _ref: observable,
            data: observable,
            ref: computed,
            created: computed,
            createdDate: computed,
            availability: computed,
            update: action.bound,
            setAvailability: action,
        })
    }

    async update(data: Partial<TDocument>, batch?: firebase.firestore.WriteBatch) {
        if (batch) {
            batch.update(this.ref, data)
        } else {
            await this.ref.update(data)
        }

        runInAction(() => {
            this.data = {
                ...this.data,
                ...data,
            }
        })
    }

    /**
     * Set new availability. If "from" is null, availability will be removed and "to" will be ignored.
     *
     * @param availability Dates of "from" and "to" fields. Null if there is no availability set.
     */
    setAvailability = async (availability: Partial<Omit<ContentAvailability, 'manual'>> | null): Promise<void> => {
        try {
            if (availability === null) { // No availability set - remove it
                this._ref.update({ 'availability': firebase.firestore.FieldValue.delete() })
                this.data.availability = undefined
                return
            }
            if (typeof availability.from === undefined && typeof availability.to === undefined) {
                throw new Error('Availability\'s "from" and "to" are not set')
            }

            let updateObject: ContentAvailability
            if (!this.availability) { // creating new availability
                updateObject = {
                    from: availability.from!,
                    to: null,
                    manual: false,
                }
            } else { // updating existing availability
                updateObject = {
                    ...this.availability,
                    ...(availability.from && { from: availability.from }),
                    ...(typeof availability.to !== 'undefined' && { to: availability.to }),
                }
            }

            await this._ref.update({
                'availability': updateObject,
            })

            this.data.availability = {
                ...this.data.availability!,
                ...(availability.from && { from: firebase.firestore.Timestamp.fromDate(availability.from) }),
                ...(availability.to && { to: firebase.firestore.Timestamp.fromDate(availability.to) }),
                ...(!this.data.availability?.manual && { manual: false }),
            }
        } catch (e) {
            alertError(i18n.t('Failed to update availability'))
            this.logger.error(e)
        }
    }

    setManualBlock = async (blockStatus: boolean) => {
        try {
            if (!this.data.availability?.from) {
                throw new Error('Availability\'s "date from" is not set')
            }

            await this.ref.update({ 'availability.manual': blockStatus })
            this.data.availability.manual = blockStatus
        } catch (e) {
            alertError(i18n.t('Failed to update availability\'s block status'))
            this.logger.error(e)
        }
    }
}
