import { MONETIZATION_FREQUENCY, PurchaseDocumentGateway, PurchaseStatus } from '@tivio/types'
import { makeAutoObservable, runInAction } from 'mobx'

import { changePurchaseStatus, nonDrmSources } from '../firebase/firestore/user'


import type User from './User'
import type { PurchaseDocument, QerkoCancelSecret, Timestamp } from '@tivio/firebase'
import type { MonetizationType, PlayerCapability } from '@tivio/types'
import type firebase from 'firebase/app'


export default class Purchase {
    /**
     * Raw data from Firestore.
     */
    private _data: PurchaseDocument
    /**
     * Reference to document in Firestore.
     */
    private _ref: firebase.firestore.DocumentReference<PurchaseDocument>
    private _user: User
    private _loading = false

    constructor(purchaseRef: firebase.firestore.DocumentReference<PurchaseDocument>, purchaseData: PurchaseDocument, user: User) {
        this._data = purchaseData
        this._ref = purchaseRef
        this._user = user

        makeAutoObservable(this)
    }

    /**
     * Purchase ID.
     */
    get id(): string {
        return this._ref.id
    }

    get ref(): firebase.firestore.DocumentReference<PurchaseDocument> {
        return this._ref
    }

    /**
     * Date the purchase was created in milliseconds.
     */
    get created(): number {
        return this._data.created.toMillis()
    }

    /**
     * In case of subscription, date the subscription will expire in milliseconds.
     */
    get expired(): number | null {
        return this._data.expired?.toMillis() ?? null
    }

    /**
     * Type of associated monetization.
     */
    get type(): MonetizationType {
        return this._data.monetization.type
    }

    /**
     * Frequency of associated monetization.
     */
    get monetizationFrequency(): MONETIZATION_FREQUENCY | undefined {
        return this._data.monetization.frequency ?? undefined
    }

    /**
     * Purchase status.
     */
    get status(): PurchaseDocument['status'] {
        return this._data.status
    }

    async changeStatus(status: PurchaseStatus) {
        if (this._loading) {
            return
        }

        this._loading = true

        try {
            const updatedFields = await changePurchaseStatus(this, status)

            runInAction(() => {
                Object.assign(this._data, updatedFields)
            })
        } catch (e) {
            throw new Error(e)
        } finally {
            this._loading = false
        }
    }

    get loading(): boolean {
        return this._loading
    }

    /**
     * Name of associated monetization.
     */
    get monetizationName(): string {
        const monetizationId = this._data.monetizationRef.id
        const findLoadedMonetization = this._user.organization.findLoadedMonetization(monetizationId)
        return findLoadedMonetization?.getName
    }

    /**
     * User store associated with the purchase.
     */
    get user(): User {
        return this._user
    }

    /**
     * Instead of regular purchase, this purchase is for someone else with voucher code.
     */
    get isVoucherPurchase(): boolean {
        return !!this._data.voucherRef || this._data.voucherRef === null
    }

    /**
     * Instead of regular purchase, this purchase is usage of a voucher (voucher code has been applied).
     */
    get isVoucherUsage(): boolean {
        return this._data.gateway === PurchaseDocumentGateway.voucher
    }

    /**
     * Database ID of connected video in case of transaction purchase.
     * Undefined in case of subscription purchase.
     */
    get videoId(): string | undefined {
        return this._data?.videoRef?.id
    }

    /**
     * Payment gateway type.
     */
    get gateway(): PurchaseDocumentGateway | undefined {
        return this._data?.gateway
    }

    /**
     * Token which can be used to cancel and refund this purchase in Qerko.
     */
    get qerkoCancelSecret(): Promise<QerkoCancelSecret> {
        return this._ref
            .collection('secrets')
            .get()
            .then(result => result.docs[0]?.data().qerkoCancelSecret)
    }

    get forcedCapabilities(): PlayerCapability[] | undefined {
        return this._data?.forcedCapabilities
    }

    async setExpired(expired: Timestamp | null) {
        const updateData = { expired }
        await this._ref.update(updateData)
        this._data = { ...this._data, ...updateData }
    }

    toggleDrmEnabled = async () => {
        const updatedData: Partial<PurchaseDocument> = {
            forcedCapabilities: this.forcedCapabilities?.length ? [] : nonDrmSources,
        }
        await this._ref.update(updatedData)
        this._data = { ...this._data, ...updatedData }
    }

    /**
     * Refunds and cancels Qerko purchase.
     */
    refundAndCancel = async () => {
        const qerkoOrderId = this.id
        const qerkoMerchantId = this._data?.qerkoMerchantId
        const qerkoCancelSecret = await this.qerkoCancelSecret

        if (!qerkoMerchantId || !qerkoCancelSecret) {
            throw new Error('Qerko merchantId or secret is not available on the doc.')
        }

        const url = `https://qerko.com/api/v2/eshop/order/${qerkoMerchantId}/${qerkoOrderId}`

        // Call Qerko API
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${qerkoCancelSecret}`,
                },
            })
            if (!response.ok) {
                throw new Error(`Qerko request failed with status ${response.status}`)
            }
        } catch (err) {
            console.error(err)
            throw new Error('Qerko request failed.')
        }

        // Change purchase status to CANCELLED
        try {
            await this.changeStatus(PurchaseStatus.CANCELLED)

            if (this.type === 'subscription' && this.monetizationFrequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT) {
                // set expired to null for one time subscription so it will immediately remove access
                await this.setExpired(null)
            }
        } catch (err) {
            console.error(err)
            throw new Error('Qerko request was successful but we could not change the status to CANCELLED.')
        }
    }
}
