import { createLogger, notEmptyFilter } from '@tivio/common'
import { VIDEO_DETAIL_ASSET_NAME, VideoCuttingStatus, VideoProcessingStatus, VideoProcessingType } from '@tivio/firebase'
import { LangCode, PublishedStatus, VideoTranscodingStatus, VideoType } from '@tivio/types'
import firebase from 'firebase/app'
import i18n from 'i18next'
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx'

import { getAsset } from '../components/asset/asset.utils'
import { createVideo } from '../creator/video.creator'
import { firebaseTimestampFromDate, getFirestore, getFunctions } from '../firebase/app'
import { toggleVideoDistribution } from '../firebase/firestore/content'
import { deleteElement } from '../firebase/firestore/element'
import { addVodMarker, addVodMarkers, deleteVodMarker, getVodMarkersCollection } from '../firebase/firestore/markerVod'
import { getGlobalTagById, getTagByPath } from '../firebase/firestore/tag'
import {
    checkCanDeleteVideo,
    getSeriesEpisodeVideoDoc,
    getVideosCollection,
    uploadSubtitlesFile,
    uploadVideoFile,
} from '../firebase/firestore/video'
import { MARKER_TYPES } from '../static/enum'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { formSectionVideoName } from '../utils/channels.utils'
import { goSectionVideoPage, goVideoPage } from '../utils/history.utils'
import { merge } from '../utils/merge.utils'
import { getTranslation, translate } from '../utils/translate.utils'
import { getVideoCoverAsset } from '../utils/video.utils'

import { ContentBase } from './content/ContentBase'
import Marker from './Marker'

import WriteBatch = firebase.firestore.WriteBatch

import type { VideoInterface } from '../components/section/SectionVideoEditor'
import type { MarkerData } from '../firebase/firestore/markerEpg'
import type { AssetResizeResult } from '../firebase/firestore/types'
import type { ContentInterface } from '../types/content'
import type Organization from './Organization'
import type Section from './Section'
import type { Tag } from './Tag'
import type {
    DocumentReference,
    OldVideoSourceField,
    TvChannelDocument,
    VideoDocument,
    VideoLinkedVideosTypeField,
    VideoSubtitlesField,
} from '@tivio/firebase'
import type { Scale, VideoSourceField } from '@tivio/types'

type Resolution = {
    resolution: string;
    progress: number;
}


type LinkedVideo = {
    id: string
    video: Video | null
    type: VideoLinkedVideosTypeField | typeof VideoType.CHILD
}

/**
 * This is the base class for all videos and playlists.
 * Videos can be either global (see Videos page in Admin) or section specific (deprecated, see Channels page in Admin).
 */
class Video extends ContentBase<VideoDocument> implements ContentInterface {
    progress = 0
    private _tags: Tag[] = []
    markers: Marker[] = []
    scanResolutions?: number[]
    videos: Video[] = []
    /**
     * Array of linked videos. Null, if video is not found in DB.
     * Property is empty if the Videos are not loaded yet. Use `loadLinkedVideos()` to lazy-load them.
     */
    linkedVideos: LinkedVideo[] | null = null
    isLoading = false
    private subscription?: () => void

    constructor(
        ref: firebase.firestore.DocumentReference<VideoDocument>,
        public data: VideoDocument,
        public organization: Organization,
        public url?: OldVideoSourceField,
        public section?: Section,
        public playlist?: Video,
    ) {
        super(ref, data, createLogger('Video'))

        this.initMarkers()
        this.initTags()
        this.scanResolutions = data.info?.scanResolutions ?? []

        makeObservable<Video, '_tags'>(this, {
            progress: observable,
            _tags: observable,
            markers: observable,
            initTags: action,
            initMarkers: action,
            addMarker: action,
            deleteMarker: action,
            updateTags: action,
            delete: action,
            updatePublishedStatus: action,
            updateName: action,
            updateSource: action,
            updateSources: action,
            setProgress: action,
            getCover: computed,
            hide: computed,
            getPublishedStatus: computed,
            getType: computed,
            getDuration: computed,
            getCreated: computed,
            getUrl: computed,
            getMarkers: computed,
            tags: computed,
            id: computed,
            getId: computed,
            getName: computed,
            cover: computed,
            banner: computed,
            landscape: computed,
            portrait: computed,
            circled: computed,
            detailBanner: computed,
            getDefaultName: computed,
            getDescription: computed,
            getDocumentPath: computed,
            getTagRefs: computed,
            getProgress: computed,
            getAssets: computed,
            getRef: computed,
            getScanResolutions: computed,
            sources: computed,
            scanResolutions: observable,
            resolutions: computed,
            hasSources: computed,
            uploadVideo: action,
            uploadSubtitles: action,
            isUploaded: computed,
            isTranscoding: computed,
            uploadRemove: action,
            playlist: observable,
            addVideos: action,
            deleteVideos: action,
            deleteUnprocessedSubtitles: action,
            updateVideosOrder: action,
            getVideos: computed,
            subtitles: computed,
            createCut: action,
            linkVideo: action,
            linkedVideosRaw: computed,
            linkedVideos: observable,
            isCut: computed,
            originalVideoRef: computed,
            loadLinkedVideos: action,
            removeLinkedVideo: action,
            isLoading: observable,
            setManualBlock: action,
            shownInRss: computed,
        })
    }

    /**
     * Subscribe to DB snapshot. Stars listening to changes.
     */
    subscribe = () => {
        if (!this.subscription) {
            this.subscription = this.ref.onSnapshot(data => {
                runInAction(() => {
                    if (!data.exists) {
                        this.logger.error('Video no longer exists.')
                    }
                    this.data = data.data() as VideoDocument
                })
            })
        }
    }

    /**
     * Unsubscribe from DB snapshot. Stops listening for changes.
     */
    unsubscribe = () => {
        if (this.subscription) {
            this.subscription()
            this.subscription = undefined
        }
    }

    /**
     * Prepare all tags for this video.
     */
    initTags = async () => {
        try {
            const tagsDb = (this.getTagRefs && await Promise.all(this.getTagRefs
                .map(async (tagRef) => await getTagByPath(tagRef.path))))
            this.tags = tagsDb?.filter(notEmptyFilter) ?? [] as Tag[]
        } catch (e) {
            this.logger.error(`Failed to load video tags: ${this.ref.path}`, e)
        }
    }

    initMarkers = async () => {
        try {
            const markersSnapshots = await getVodMarkersCollection(this).get()
            this.setMarkers = markersSnapshots.docs.map(
                markersSnapshot => {
                    const markerData = markersSnapshot.data()
                    return new Marker(markersSnapshot.ref, markerData, undefined, this)
                },
            )
        } catch (e) {
            this.logger.error(`Failed to load video markers: ${this.ref.path}`, e)
        }
    }

    addMarker = async (markerData: MarkerData) => {
        try {
            const markerRef = await addVodMarker(this, markerData)
            const marker = new Marker(markerRef, markerData, undefined, this)

            this.setMarkers = this.getMarkers.concat([marker])

            alertSuccess('Marker added')
        } catch (e) {
            alertError(i18n.t('Failed to add markerEntity'))
            console.error(e)
        }
    }

    deleteMarker = async (marker: Marker) => {
        try {
            await deleteVodMarker(this, marker)

            this.setMarkers = this.getMarkers.filter(markerFilter => markerFilter.getId !== marker.getId)

            alertSuccess(i18n.t('Marker deleted'))
        } catch (e) {
            alertError(i18n.t('Failed to delete markerEntity'))
            console.error(e)
        }
    }

    getUploadPath = (fileExtension: string, element: Video) => {
        if (!element.section) {
            return ''
        }
        return formSectionVideoName(fileExtension, element)
    }

    /**
     * Updates this video document.
     *
     * @param data data to update
     * @param batch optional firestore batch if this operation should be executed in batch
     */
    async update(data: Partial<VideoDocument>, batch?: WriteBatch) {
        if (!this.subscription) {
            this.data = {
                ...this.data,
                ...data,
            }
        }

        if (batch) {
            batch.update(this.ref, data)
        } else {
            await this.ref.update(data)
        }
    }

    updateTags = async (tags: Tag[]) => {
        try {
            this.tags = tags
            await this.update({ tags: this.tags.map(item => item.getRef) })
        } catch (e) {
            alertError(translate('Failed to update tags'))
            console.error(e)
        }
    }

    addTag(tag: Tag) {
        this.tags.push(tag)
    }

    hasTag(tag: Tag) {
        return this.tags.some(someTag => someTag.id === tag.id)
    }

    hasTagInLinkedVideo(linkedVideo: LinkedVideo, tag: Tag): boolean {
        const video = linkedVideo.video

        if (video === null) {
            return false
        }

        return video.tags.some(someTag => someTag.id === tag.id)
    }

    hasTagInLinkedVideos(tag: Tag): boolean {
        const linkedVideos = this.linkedVideos

        if (linkedVideos === null) {
            return false
        }

        return linkedVideos.find(linkVideo => this.hasTagInLinkedVideo(linkVideo, tag)) !== undefined
    }

    hasTagWithId(tagId: string): boolean {
        return this.tags.some(someTag => someTag.id === tagId)
    }

    /**
     * Removes video from DB.
     */
    delete = async () => {
        if (await checkCanDeleteVideo(this)) {
            this.unsubscribe()
            await this.ref.delete()

            return true
        }

        return false
    }

    /**
     * Redirects browser to video edit page in section.
     *
     * @deprecated Use goUpdatePage instead. Sections are deprecated.
     */
    goSectionUpdatePage = () => {
        goSectionVideoPage(this)
    }

    /**
     * Redirects browser to video edit page.
     */
    goUpdatePage = () => {
        goVideoPage(this.id)
    }

    updatePublishedStatus = async (publishedStatus: PublishedStatus) => {
        try {
            await this.ref.update({ publishedStatus })
            this.setPublishedStatus = publishedStatus
        } catch (e) {
            alertError(i18n.t('Failed to update published status'))
            this.logger.error(e)
        }
    }

    updateName = async (name: string) => {
        const originalName = this.getName

        try {
            this.setName = name
            await this.ref.update({ name })
        } catch (e) {
            this.setName = originalName
            alertError(i18n.t('Failed to update video name'))
            this.logger.error(e)
        }
    }

    updateSource = async (source: string) => {
        try {
            await this.ref.update({ url: { source } })
        } catch (e) {
            this.logger.error(e)
            throw new Error(e)
        }
    }

    updateSources = async (sources: VideoSourceField[]) => {
        try {
            await this.ref.update({ sources })
            this.sources = sources
        } catch (e) {
            this.logger.error(e)
            throw new Error(e)
        }
    }

    private getCoverAsset() {
        return getVideoCoverAsset(this.getAssets ?? {}) ?? null
    }

    getAsset = (presetName: string, scale?: Scale) => {
        return getAsset(
            this.getAssets ?? {},
            presetName,
            scale,
        )
    }

    setProgress = (progress: number) => {
        this.progress = progress
    }

    get getCover() {
        return this.getCoverAsset() ??
            this.data.cover ??
            ''
    }

    get hide() {
        return this.data.hide
    }

    get getPublishedStatus() {
        return this.data.publishedStatus
    }

    get getType() {
        return this.data.type
    }

    get getDuration() {
        return this.data.duration
    }

    /**
     * @deprecated - use createdDate
     */
    get getCreated() {
        return this.createdDate
    }

    /**
     * @deprecated this is old url format, use {@link sources} instead.
     */
    get getUrl() {
        return this.data.url
    }

    get getMarkers() {
        return this.markers
    }

    get tags() {
        return this._tags
    }

    /**
     * @deprecated Use `id` property instead.
     */
    get getId() {
        return this.ref.id
    }

    /**
     * Returns video id (Firestore reference ID).
     */
    get id() {
        return this.ref.id
    }

    get getName() {
        return getTranslation(this.data.name, this.organization.languages)
    }

    // TODO (TIV-2029): remove once we migrate all documents to new format (object with translations)
    get getNameTranslations() {
        return typeof this.data.name === 'object'
            ? this.data.name
            : null
    }

    get getShortName() {
        return this.data.shortName ? getTranslation(this.data.shortName, this.organization.languages) : undefined
    }

    get getShortNameTranslations() {
        return this.data.shortName
    }

    get getUrlName() {
        return typeof this.data.urlName === 'object'
            ? this.data.urlName
            : null
    }

    get cover() {
        return this.data.assets?.cover?.['@1']?.background
            // This is for backward compatibility, when we stop using video.cover
            // attribute in the DB, we can remove this
            ?? this.data?.cover
            ?? null
    }

    get banner() {
        return this.data.assets?.banner?.['@1']?.background
            // This is for backward compatibility, when we stop using video.cover
            // attribute in the DB, we can remove this
            ?? this.data?.cover
            ?? null
    }

    get errors() {
        return this.data.errors
    }

    get landscape() {
        return this.data.assets?.landscape?.['@1']?.background
            ?? this.data.assets?.cover?.['@1']?.background
            // This is for backward compatibility, when we stop using video.cover
            // attribute in the DB, we can remove this
            ?? this.data?.cover
            ?? null
    }

    get portrait() {
        return this.data.assets?.portrait?.['@1']?.background
            // This is for backward compatibility, when we stop using video.cover
            // attribute in the DB, we can remove this
            ?? this.data.assets?.cover?.['@1']?.background
            ?? this.data?.cover
            ?? null
    }

    get circled() {
        return this.data.assets?.circled?.['@1']?.background
            // This is for backward compatibility, when we stop using video.cover
            // attribute in the DB, we can remove this
            ?? this.data?.cover
            ?? null
    }

    get square() {
        return this.data.assets?.square?.['@1']?.background ?? null
    }

    get detailBanner() {
        return this.data.assets?.[VIDEO_DETAIL_ASSET_NAME]?.['@1']?.background ?? null
    }

    get getDefaultName() {
        return this.data.defaultName
    }

    get getDescription() {
        return getTranslation(this.data.description, this.organization.languages)
    }

    // TODO (TIV-2029): remove once we migrate all documents to new format (object with translations)
    get getDescriptionTranslations() {
        return typeof this.data.description === 'object'
            ? this.data.description
            : null
    }

    get descriptionRich() {
        return this.data.descriptionRich
    }

    get getDocumentPath() {
        return this.ref.path
    }

    get getTagRefs() {
        return this.data.tags
    }

    get getProgress() {
        return this.progress
    }

    get getAssets() {
        return this.data.assets
    }

    get getRef() {
        return this.ref
    }

    get getScanResolutions() {
        return this.data?.info?.scanResolutions
    }

    /**
     * Array of all available video resolutions and progress of theirs encoding.
     * Empty array if no resolutions are available.
     */
    get resolutions(): Resolution[] {
        if (!this.data.profiles) {
            return []
        }

        return Object.entries(this.data.profiles).map(([resolution, profile]) => {
            return {
                resolution,
                progress: profile.progress,
            }
        })
    }

    set setCover(resizeResults: AssetResizeResult[]) {
        if (resizeResults.length === 0) {
            return
        }

        this.data.cover = resizeResults[0].url


        const updateObject = {
            assets: {
                cover: {},
            },
        }

        resizeResults.forEach(result => {
            // @ts-expect-error
            updateObject.assets.cover[result.scale] = { background: result.url }
        })

        // @ts-expect-error
        this.data = merge(this.data, updateObject)
    }

    set setName(name: string) {
        this.data.name = name
    }

    set setDuration(duration: number) {
        this.data.duration = duration
    }

    set setDescription(description: string) {
        this.data.description = description
    }

    set setPublishedStatus(publishedStatus: PublishedStatus) {
        this.data.publishedStatus = publishedStatus
    }

    /**
     * TODO: Remove
     * @deprecated
     */
    set setUrl(url: { source: string }) {
        this.data.url = url
    }

    /**
     * Set modified video sources.
     */
    set sources(sources: VideoSourceField[]) {
        this.data.sources = sources
    }

    /**
     * Get video sources as an array of source settings and URLs.
     */
    get sources() {
        return this.data.sources ?? []
    }

    set setMarkers(markers: Marker[]) {
        this.markers = markers
    }

    set tags(tags: Tag[]) {
        this._tags = tags
    }

    /**
     * Check if video has any usable source URL. First, it checks newer "sources" field,
     * then old deprecated "url" field as a fallback.
     */
    get hasSources() {
        return !!this.data.sources?.length
            || !!this.data.url?.hls
            || !!this.data.url?.dash
            || !!this.data.url?.source
    }

    /**
     * List of all subtitles (those that went through transcoding + unprocessed ones)
     */
    get subtitles(): VideoSubtitlesField[] {
        return this.data.encodingMetadata?.subtitles ?? []
    }

    get feedVisible(): boolean {
        return Boolean(this.data.feedVisible)
    }

    get seasonNumber(): number | undefined {
        return this.data.seasonNumber
    }

    get episodeNumber(): number | undefined {
        return this.data.episodeNumber
    }

    /**
     * Uploads video files and store them to DB.
     */
    uploadVideo = async (filesList: FileList | null) => {
        if (!filesList) {
            console.error('No files to upload')
            return
        }
        const files = Array.from(filesList)
        try {
            await Promise.all(
                files.map(
                    file => uploadVideoFile(file, this),
                ),
            )
        } catch (e) {
            this.logger.error(e)
        }
    }

    reEncode = async () => {
        const reEncodeVideo = getFunctions().httpsCallable('reEncodeVideo')
        await reEncodeVideo({
            inputFileUrl: this.data.inputFileUrl,
            videoDocumentId: this.id,
        })
    }

    /**
     * Uploads subtitles file and store it to DB.
     */
    uploadSubtitles = (file: File, language: LangCode) => {
        return uploadSubtitlesFile(file, language, this)
    }

    /**
     * Sets new value for 'encodingMetadata.subtitles' field.
     */
    setSubtitles = (subtitles: VideoSubtitlesField[]) => {
        return this.update({
            encodingMetadata: {
                ...this.data.encodingMetadata,
                subtitles,
            },
        })
    }

    /**
     * Removes subtitles from DB.
     */
    deleteUnprocessedSubtitles = (language: LangCode) => {
        return this.update({
            encodingMetadata: {
                ...this.data.encodingMetadata,
                subtitles: this.subtitles.filter(subtitles => !(subtitles.language == language && subtitles.draft)),
            },
        })
    }

    /**
     * Returns true if the video was uploaded via Tivio Admin.
     * Returns false if the video has external sources or currently is being uploaded or has been uploaded but not transcoded yet.
     */
    get isUploaded() {
        return this.data.transcodingStatus === VideoTranscodingStatus.DONE 
            || this.data.sources !== undefined
    }

    /**
     * Returns true if the video is being transcoded or waiting for transcoding.
     */
    get isTranscoding() {
        return this.data.transcodingStatus === VideoTranscodingStatus.IN_PROGRESS
            || this.data.transcodingStatus === VideoTranscodingStatus.ON_HOLD
            || this.data.reuploadTranscodingStatus === VideoTranscodingStatus.IN_PROGRESS
            || this.data.reuploadTranscodingStatus === VideoTranscodingStatus.ON_HOLD
    }

    /**
     * Returns true if the video transcoding failed.
     */
    get transcodingFailed() {
        return this.data.transcodingStatus === VideoTranscodingStatus.ERROR
            || this.data.reuploadTranscodingStatus === VideoTranscodingStatus.ERROR
    }

    /**
     * Returns true if the video is waiting in queue for transcoding.
     */
    get isOnHold() {
        return this.data.transcodingStatus === VideoTranscodingStatus.ON_HOLD
            || this.data.reuploadTranscodingStatus === VideoTranscodingStatus.ON_HOLD
    }

    /**
     * Removes all records of uploaded video from DB.
     * Doesn't remove video file from storage, though.
     */
    uploadRemove = () => {
        // small util to satisfy VideoDocument attribute types
        const deleteField = <T extends keyof VideoDocument>() => {
            return firebase.firestore.FieldValue.delete() as VideoDocument[T]
        }

        return this.update({
            encodingMetadata: deleteField<'encodingMetadata'>(),
            info: deleteField<'info'>(),
            sources: deleteField<'sources'>(),
            profiles: deleteField<'profiles'>(),
            duration: deleteField<'duration'>(),
            transcodingStatus: deleteField<'transcodingStatus'>(),
            reuploadTranscodingStatus: deleteField<'reuploadTranscodingStatus'>(),
            url: deleteField<'url'>(),
        })
    }

    // PLAYLIST START
    // TODO: Refactor ASAP. Playlist should have its own class (store) under this video store.
    addVideos = async (videoInterfaces: VideoInterface[]) => {
        try {
            if (!this.section) {
                return // in not in section, break
            }
            const videos = await this.section.uploadVideos(
                videoInterfaces,
                true,
            )

            this.setVideos = this.getVideos.concat(videos)

            await this.ref.update({ videos: this.getVideos.map(video => video.getRef) })

            return this.getVideos
        } catch (e) {
            this.logger.error(e)
            throw new Error(e)
        }
    }

    deleteVideos = async (videosToDelete: Video[]) => {
        try {
            await Promise.all(
                videosToDelete.map((video) => deleteElement(video)),
            )

            this.setVideos = this.getVideos.filter(
                video => !videosToDelete.some(
                    videosToDelete => videosToDelete.id === video.id,
                ),
            )
        } catch (e) {
            this.logger.error(e)
            throw new Error(e)
        }
    }

    updateVideosOrder = async (videosInterfaces: VideoInterface[]) => {
        try {
            const videos = videosInterfaces
                .map(videoInterface => videoInterface.videoInstance)
                .filter(notEmptyFilter)

            await this.ref.update({ videos: videos.map(video => video.ref) })

            this.setVideos = videos
        } catch (e) {
            alertError(i18n.t('Failed to update videos order'))
            this.logger.error(e)
        }
    }

    get getVideos() {
        return this.videos
    }

    set setVideos(videos: Video[]) {
        this.videos = videos
    }
    // PLAYLIST END

    /**
     * Creates new video as a cut of this video. New video will be linked to this video.
     * Markers are also cut according to start/end time of the cut.
     *
     * @param from Start cut from this point (in ms).
     * @param to End cut at this point (in ms).
     * @param type If this cut is common cut or trailer cut.
     * @returns Firebase document reference to created video cut.
     */
    createCut = async (from: number, to: number, type: VideoLinkedVideosTypeField) => {
        // We don't want to include externals, because it might interfere with import function (e.g. joj metadata)
        // We're only interested in virtualVideoData and omit other fields
        // eslint-disable-next-line
        const { url, urlName, transcodingStatus, linkedVideos, externals, availability, monetizations, feedVisible, ...virtualVideoData } = this.data

        let virtualVideoDataType = VideoType.CUT
        if (type === VideoType.TRAILER || type === VideoType.TASTING) {
            delete virtualVideoData.tags
            virtualVideoDataType = type === VideoType.TRAILER ? VideoType.TRAILER : VideoType.TASTING
        }

        if (type === VideoType.TASTING) {
            delete virtualVideoData.profiles
            delete virtualVideoData.sources
            delete virtualVideoData.inputFileUrl
            from = 0
        }

        // we need to create CUT video and update original video at the same time (due to onVideoChange - isTrailerCut)
        const batch = getFirestore().batch()

        const newName = this.getNameTranslations ?? {}

        const newVideoRef = getVideosCollection().doc()
        const newVideoData = {
            ...virtualVideoData,
            name: newName,
            originalVideoRef: this.getRef,
            // we want to have these types of videos to be unlisted by default, so they are playable but not visible in screens / rows / search
            publishedStatus: type === VideoType.TASTING ? PublishedStatus.DRAFT : PublishedStatus.UNLISTED,
            created: firebaseTimestampFromDate(new Date()),
            type: virtualVideoDataType,
            duration: to - from,
            cuttingStatus: VideoCuttingStatus.WAITING,
        }

        batch.set(newVideoRef, newVideoData)

        const markers = this.markers.reduce((acc, marker) => {
            if (
                marker.from <= to
                && marker.to >= from
                && marker.getType !== MARKER_TYPES.CHAPTER
                && marker.getType !== MARKER_TYPES.START
                && marker.getType !== MARKER_TYPES.END
            ) {
                acc.push({
                    name: marker.getName,
                    from: marker.from < from ? (from / 1000) : marker.getFrom,
                    to: marker.to > to ? (to / 1000) : marker.getTo,
                    type: marker.getType,
                })
            }
            return acc
        }, [] as MarkerData[])

        // New video START and END
        from = from < 0
            ? 0
            : from / 1000 // to ms

        to = this.getDuration
            ? (to > this.getDuration ? this.getDuration : to) // if duration is known, ensure that "to" is not bigger than duration
            : to
        to = to / 1000 // to ms

        if (type !== VideoType.TASTING) {
            markers.push({
                name: 'Cut START',
                from: from,
                to: from,
                type: MARKER_TYPES.START,
            })
        }
        markers.push({
            name: 'Cut END',
            from: to,
            to: to,
            type: MARKER_TYPES.END,
        })

        await addVodMarkers(newVideoRef as unknown as FirebaseFirestore.DocumentReference, markers)
        await this.linkVideo(newVideoRef, type, batch, newVideoData)
        await batch.commit()

        const commitedVideoData = (await newVideoRef.get()).data()! // must exist, see addVideo above
        const video = createVideo(newVideoRef, commitedVideoData, this.organization)

        this.organization.addVideo(video)
        return newVideoRef
    }

    /**
     * Creates new link to another video.
     *
     * @param videoRef Reference to linked video.
     * @param type Link type.
     * @param batch optional firestore batch if this operation should be executed in batch
     * @param preCommitedVideoData optional. When executing in batch, video.data() is null.
     */
    linkVideo = async (videoRef: firebase.firestore.DocumentReference<VideoDocument>, type: VideoLinkedVideosTypeField, batch?: WriteBatch, preCommitedVideoData?: VideoDocument) => {
        try {
            const newLink = {
                videoRef,
                type,
            }

            const video = await videoRef.get()
            const videoData = video.data() || preCommitedVideoData

            if (!videoData) {
                throw new Error(`Video ${videoRef.path} does not exist or have no data, can not link it`)
            }

            const videoEntity = createVideo(videoRef, videoData, this.organization)

            if (Array.isArray(this.data.linkedVideos)) {
                this.data.linkedVideos.push(newLink)
            } else {
                this.data.linkedVideos = [newLink]
            }

            if (videoData.originalVideoRef) {
                // TODO what should we actually do when video already has a parent?
                console.warn(
                    `Video ${videoRef.path} already has originalVideoRef ${videoData.originalVideoRef.path}, will override it with ${this.ref.path} anyway.`,
                )
            }

            await videoEntity.update({
                originalVideoRef: this.ref,
            }, batch)
            await this.update({ linkedVideos: this.data.linkedVideos }, batch)
        } catch (e) {
            alertError(translate('Failed to update video links.'))
            console.error(e)
        }
    }

    /**
     * Array of linked videos as stored in DB (raw format). For full video object use `linkedVideos`.
     */
    get linkedVideosRaw() {
        return this.data.linkedVideos
    }

    /**
     * True if this video is a cut (or trailer cut) of another video.
     */
    get isCut() {
        return !!this.data.originalVideoRef && !this.data.isDuplicate
    }

    /**
     * Reference to the original video.
     */
    get originalVideoRef() {
        return this.data.originalVideoRef || undefined
    }

    get inputFileUrl() {
        return this.data.inputFileUrl
    }

    /**
     * Loads linked videos and store them as MobX store object. If the video is not found in DB,
     * `null` will be stored. You can access the result using `linkedVideos` property.
     */
    loadLinkedVideos = async () => {
        const linkedVideosRaw = this.linkedVideosRaw
        const hasLinkedVideos = !!linkedVideosRaw?.length
        const videoIds: string[] = [] // ids of all linked videos and parent video (if any)
        const videoObjects: LinkedVideo[] = []

        if (!hasLinkedVideos && !this.isCut) {
            this.linkedVideos = [] // nothing to process
            return
        }
        if (this.isLoading) {
            return // loading
        }

        this.isLoading = true

        if (hasLinkedVideos) { // get all linked videos ref
            linkedVideosRaw.forEach(link => {
                videoIds.push(link.videoRef.id)
            })
        }
        if (this.isCut) { // get parent video ref (if this video is a cut)
            videoIds.push(this.data.originalVideoRef!.id)
        }

        // get all videos by refs
        const query = await getFirestore().collection('videos')
            .where(firebase.firestore.FieldPath.documentId(), 'in', videoIds).get() as firebase.firestore.QuerySnapshot<VideoDocument>

        // Create Video object from links (if any)
        if (hasLinkedVideos) {
            linkedVideosRaw.forEach(link => {
                const video = query.docs.find(doc => doc.id === link.videoRef.id)
                videoObjects.push({
                    id: link.videoRef.id,
                    video: video ? createVideo(link.videoRef, video.data(), this.organization) : null,
                    type: link.type,
                })
            })
        }
        // Create video object from parent video (if any)
        if (this.isCut) {
            const video = query.docs.find(doc => doc.id === this.data.originalVideoRef!.id)
            videoObjects.push({
                id: this.data.originalVideoRef!.id,
                video: video ? createVideo(this.data.originalVideoRef!, video.data(), this.organization) : null,
                type: VideoType.CHILD,
            })
        }

        runInAction(() => {
            this.linkedVideos = videoObjects
            this.isLoading = false
        })
    }

    /**
     * Removes link between this video and linked video.
     *
     * @param videoId ID of the video to remove.
     */
    removeLinkedVideo = async (videoId: string) => {
        if (!this.data.linkedVideos) {
            return false // No links to remove
        }
        const linkedVideos = [...this.data.linkedVideos]

        const index = linkedVideos.findIndex(link => link.videoRef.id === videoId)
        if (index === -1) {
            return false // Link with this ID doesn't exist.
        }
        linkedVideos.splice(index, 1)

        try {
            await this.update({
                linkedVideos,
            })

            this.data.linkedVideos = linkedVideos
            if (this.data.linkedVideos.length === 0) {
                this.linkedVideos = [] // We must clear it here, because loadLinkedVideos won't process with empty linkedVideos
            }

            // Also delete backlink from child video.
            const childVideoSnapshot = await getFirestore().doc(`videos/${videoId}`).get()
            if (childVideoSnapshot.exists) {
                await childVideoSnapshot.ref.update({
                    originalVideoRef: firebase.firestore.FieldValue.delete(),
                })
            } else {
                this.logger.warn(`Reference to this video in child video not removed. Video ${videoId} not found.`)
            }
            return true
        } catch (e) {
            alertError(translate('Failed to update video links.'))
            console.error(e)
            return false
        }
    }

    get type(): 'video' | 'sectionVideo' | 'playlist' {
        if (this.playlist) {
            return 'playlist'
        } else if (this.section) {
            return 'sectionVideo'
        } else {
            return 'video'
        }
    }

    getOriginalVideo = () => {
        if (
            this.data.originalVideoRef === undefined ||
            this.data.originalVideoRef === null ||
            this.linkedVideos === null
        ) {
            return null
        }

        const originalVideoLink = this.linkedVideos.find(({ video }) => video?._ref === this.data.originalVideoRef)

        if (originalVideoLink === undefined) {
            return null
        }

        return originalVideoLink.video
    }

    async toggleDistribution(accessTag: Tag, isActive: boolean) {
        await toggleVideoDistribution(this, accessTag, isActive)
    }

    hasDistribution(accessTag: Tag) {
        // Fallback for videos which were distributed the old way (just by having an access tag), to show in UI that they're part of distribution
        if (this.getType !== VideoType.VIRTUAL_PROGRAM && this.hasTag(accessTag)) {
            return true
        }
        return Boolean(
            this.linkedVideos?.find(({ video }) => video?.getType === VideoType.VIRTUAL_PROGRAM && this.hasTagInLinkedVideos(accessTag)),
        )
    }

    async setProductPlacement(): Promise<void> {
        const productPlacementTag = await getGlobalTagById(process.env.REACT_APP_PRODUCT_PLACEMENT_GLOBAL_TAG_ID)

        if (productPlacementTag !== undefined) {
            const originalVideo = this.getOriginalVideo()

            if (originalVideo !== null) {
                // For "tasting" videos we want to set the "Product placement" tag for the parent video
                originalVideo.addTag(productPlacementTag)

                await originalVideo.updateTags(originalVideo.tags)
            } else {
                this.addTag(productPlacementTag)
    
                await this.updateTags(this.tags)
            }
        }
    }

    hasProductPlacement(): boolean {
        const originalVideo = this.getOriginalVideo()

        if (originalVideo !== null) {
            return originalVideo.hasTagWithId(process.env.REACT_APP_PRODUCT_PLACEMENT_GLOBAL_TAG_ID)
        }

        return this.hasTagWithId(process.env.REACT_APP_PRODUCT_PLACEMENT_GLOBAL_TAG_ID)
    }

    async setIsFeedVisible(isActive: boolean) {
        try {
            await this.update({ feedVisible: isActive })
        } catch (e) {
            alertError(i18n.t('Failed to update feed visibility'))
            this.logger.error(e)
        }
    }

    async setShowInRss(isActive: boolean) {
        const processing = this.data.processing
        const audioProcessing = processing && 'AUDIO' in processing ? processing.AUDIO : null
        const audioStatus = audioProcessing?.status
        const canEdit = !audioStatus || [VideoProcessingStatus.WAITING, VideoProcessingStatus.FAILED].includes(audioStatus)
        if (!canEdit) {
            alertError(i18n.t('Can\'t toggle RSS in the current processing status'))
            return
        }
        if (isActive) {
            await this.update({
                processing: {
                    ...this.data.processing,
                    AUDIO: {
                        status: VideoProcessingStatus.WAITING,
                        waitingForProcessing: [VideoProcessingType.TRANSCODING],
                        updatedAt: firebaseTimestampFromDate(new Date()),
                    },
                },
            })
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { AUDIO, ...processingWithoutAudio } = (this.data.processing ?? {}) as { AUDIO?: VideoProcessingStatus }
            await this.update({
                processing: processingWithoutAudio,
            })
        }
    }

    get shownInRss() {
        const processing = this.data.processing
        const audioProcessing = processing && 'AUDIO' in processing ? processing.AUDIO : null
        const audioStatus = audioProcessing?.status
        return Boolean(audioStatus && audioStatus !== VideoProcessingStatus.FAILED)
    }

    setSeriesInfo = async (seriesTag: Tag, seasonNumber: number, episodeNumber: number) => {
        const foundVideo = await getSeriesEpisodeVideoDoc(this.organization, seriesTag.getRef, seasonNumber, episodeNumber)

        if (foundVideo && foundVideo.id !== this.id) {
            alertError(i18n.t('Video with this series info already exists'))
            return
        }

        try {
            await this.update({ seasonNumber, episodeNumber })
        } catch (e) {
            alertError(i18n.t('Failed to update season number'))
            this.logger.error(e)
        }
    }

    updateTranslationField = (fieldName: 'descriptionRich', langCode: LangCode, value: string) => {
        this.update({
            [fieldName]: {
                ...this.data[fieldName],
                [langCode]: value,
            },
        })
    }

    getVideoProfileDuration = async (tvChannelRef: DocumentReference<TvChannelDocument>) => {
        const tvChannelSnapshot = await tvChannelRef.get()

        if (tvChannelSnapshot !== undefined) {
            const profileRefs = (tvChannelSnapshot.data() as TvChannelDocument).profileRefs

            if (profileRefs !== undefined && 
                this.data.profiles !== undefined && 
                this.data.profiles[profileRefs[0].id] !== undefined
            ) {
                return this.data.profiles[profileRefs[0].id].duration
            }
        }

        return this.getDuration
    }
}

export default Video
export type {
    Resolution,
    LinkedVideo,
}
