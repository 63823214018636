import { notEmptyFilter } from '@tivio/common'
import firebase from 'firebase/app'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { getFirestore } from '../firebase/app'
import { updateMonetization } from '../firebase/firestore/monetization'
import Logger from '../logger'
import { ON_EACH_CHAPTER } from '../static/constants'
import { alertError } from '../utils/alert.utils'
import { isEmpty } from '../utils/array.utils'
import { goMonetizationPage } from '../utils/history.utils'
import { getVariantId } from '../utils/monetization.utils'


import type { AdProfileDocumentWithId } from '../firebase/firestore/converters'
import type Organization from './Organization'
import type {
    MembershipDocument,
    MonetizationAdVariantField,
    MonetizationAdVariantStrategiesField,
    MonetizationDocument,
    MonetizationPlacementField,
    MonetizationPurchasableVariantField,
    MonetizationRecoveryField,
    MonetizationVariantField,
    PricesField,
    PurchasableMonetizationDocument,
    StrategyName,
    SubscriptionMonetizationDocument,
} from '@tivio/firebase'
import type { MONETIZATION_DURATION, MONETIZATION_FREQUENCY, Translation, TvStreamType } from '@tivio/types'


const logger = new Logger('Monetization')

export interface PlacementItem {
    id: string
    name: string
}

export type ExtendedMonetizationVariant = ExtendedPaidMonetizationVariant | ExtendedAdMonetizationVariant

export interface ExtendedPaidMonetizationVariant extends MonetizationPurchasableVariantField {
    id: string
    name: string
}

export interface ExtendedAdMonetizationVariant extends MonetizationAdVariantField {
    id: string
    name: string
}

const convertToMonetizationVariant = ({ id, name, ...rest }: ExtendedMonetizationVariant): MonetizationVariantField =>
    ({ ...rest })

type UpdateAdSlotsOperation = {
    type: 'add' | 'replace',
    // slots that will be added to current or replace current
    adSlots: AdProfileDocumentWithId[]
} | {
    type: 'delete',
    // indices of ad slots that will be deleted
    adSlotsIndicesToDelete: number[]
}

export const DEFAULT_VARIANT_NAME = 'Default'
export const DEFAULT_VARIANT_ID = 'DefaultId'

const minToMs = (min: number) => min * 60 * 1000

export const DEFAULT_MIDROLL_INTERVAL = minToMs(5)

class Monetization {
    private readonly ref: firebase.firestore.DocumentReference<MonetizationDocument>
    private firestoreData: MonetizationDocument
    private readonly organization: Organization
    private videos: PlacementItem[] = []
    private channels: PlacementItem[] = []
    private tvChannels: PlacementItem[] = []
    private sections: PlacementItem[] = []

    private _variants: ExtendedMonetizationVariant[] = []


    constructor(
        ref: firebase.firestore.DocumentReference<MonetizationDocument>,
        firestoreData: MonetizationDocument,
        organization: Organization,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.organization = organization

        makeAutoObservable(this)
        this.initPlacementItems()
    }

    /**
     * Computes id, name for each variant as id, name of related monetization
     * Can't be called in constructor, because needs fully loaded organizations.monetizations
     */
    initVariants() {
        try {
            this.variants = this.firestoreData.variants?.map((variant: MonetizationVariantField) => {
                if (variant.type === 'default') {
                    return { ...variant, id: DEFAULT_VARIANT_ID, name: DEFAULT_VARIANT_NAME }
                } else {
                    const activatingMonetizationId = variant.activatingMonetizationRef?.id ?? ''
                    const name = this.organization?.findLoadedMonetization(activatingMonetizationId)?.getName ?? ''
                    const id = getVariantId(activatingMonetizationId, variant.activatingDuration)
                    return { ...variant, id, name }
                }
            }) ?? []
        } catch (e) {
            alertError('Failed to fetch Monetization items')
            console.error('Failed to fetch Monetization items', e)
        }
    }

    async initPlacementItems() {
        try {
            const placements = this.getPlacements

            if (!placements) {
                return
            }

            // TODO cache this?
            const videos = await this.getPlacementItemsRefs(placements.videoRefs)
            if (videos) {
                this.setVideos = videos
            }
            const channels = await this.getPlacementItemsRefs(placements.channelRefs)
            if (channels) {
                this.setChannels = channels
            }
            const tvChannel = await this.getPlacementItemsRefs(placements.tvChannelRefs)
            if (tvChannel) {
                this.setTvChannels = tvChannel
            }
            const sections = await this.getPlacementItemsRefs(placements.sectionRefs)
            if (sections) {
                this.setSections = sections
            }
        } catch (e) {
            alertError('Failed to fetch Monetization items')
            console.error('Failed to fetch Monetization items', e)
        }
    }

    async getPlacementItemsRefs(refs?: firebase.firestore.DocumentReference[]) {
        try {
            if (!refs) {
                return null
            }

            const items = await Promise.all(refs.map(
                async itemRef => {
                    const itemSnapshot = await itemRef.get()

                    if (itemSnapshot.data()) {
                        return {
                            id: itemRef.id,
                            name: itemSnapshot.data()?.name,
                        } as PlacementItem
                    }
                },
            ))

            return items.filter(notEmptyFilter)
        } catch (e) {
            alertError('Failed to fetch Monetization placement items')
            console.error('Failed to fetch Monetization placement items', e)
        }
    }

    get getVideos() {
        return this.videos
    }

    set setVideos(videos: PlacementItem[]) {
        this.videos = videos
    }

    get getChannels() {
        return this.channels
    }

    set setChannels(channels: PlacementItem[]) {
        this.channels = channels
    }

    get getTvChannels() {
        return this.tvChannels
    }

    set setTvChannels(tvChannels: PlacementItem[]) {
        this.tvChannels = tvChannels
    }

    get getSections() {
        return this.sections
    }

    set setSections(sections: PlacementItem[]) {
        this.sections = sections
    }

    get getRef() {
        return this.ref
    }

    get getId() {
        return this.ref.id
    }

    get getType() {
        return this.firestoreData.type
    }

    get getOrganization() {
        return this.organization
    }

    get getName() {
        return this.firestoreData.title
    }

    get getBenefits() {
        return this.firestoreData.benefits ?? []
    }

    get getPlacements() {
        return this.firestoreData.placements as MonetizationPlacementField
    }

    get conditionPlacements() {
        return this.getPlacements?.conditions ?? []
    }

    get hasPlacements() {
        return this.videos.length > 0 ||
            this.sections.length > 0 ||
            this.tvChannels.length > 0 ||
            this.channels.length > 0 ||
            this.conditionPlacements.length > 0
    }

    get getPrices() {
        return (this.defaultVariant as ExtendedPaidMonetizationVariant)?.prices ?? {}
    }

    get getFrequency() {
        return (this.firestoreData as SubscriptionMonetizationDocument).frequency
    }

    get getDurationDays() {
        return (this.firestoreData as SubscriptionMonetizationDocument).durationDays
    }

    get getStrategies() {
        return (this.defaultVariant as ExtendedAdMonetizationVariant)?.strategies ?? {}
    }

    get getMembershipRef() {
        return (this.firestoreData as SubscriptionMonetizationDocument).membershipRef
    }

    get recovery() {
        return (this.firestoreData as PurchasableMonetizationDocument).recovery
    }

    get purchaseDisabled() {
        return (this.firestoreData as SubscriptionMonetizationDocument).purchaseDisabled
    }

    get subscriptionIsHidden() {
        return (this.firestoreData as SubscriptionMonetizationDocument).subscriptionIsHidden
    }

    get isPurchasableAsVoucher() {
        return (this.firestoreData as PurchasableMonetizationDocument).isPurchasableAsVoucher
    }

    updateTitle = async (title: string) => {
        try {
            await updateMonetization(this, { title })

            this.firestoreData.title = title
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization title attribute'))
            logger.error(e)
        }
    }

    updateName = async (title: string) => {
        try {
            await updateMonetization(this, { title })

            this.firestoreData.title = title
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization name attribute'))
            logger.error(e)
        }
    }

    updateBenefits = async (benefits: Translation[]) => {
        const filledBenefits: Translation[] = benefits.filter(benefit => Object.values(benefit).some(lang => lang !== '')) // Remove empty translations

        try {
            await updateMonetization(this, { benefits: filledBenefits })
            this.firestoreData.benefits = filledBenefits
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization benefits attribute'))
            logger.error(e)
        }
    }

    updateIsPurchasableAsVoucher = async (isPurchasableAsVoucher: boolean) => {
        if (!['transaction', 'subscription'].includes(this.firestoreData.type)) {
            const errorMessage = i18n.t('Cannot update Monetization is purchasable as voucher attribute on monetization of type {{type}}', {
                type: this.firestoreData.type,
            })
            alertError(errorMessage)
            logger.error(errorMessage)
            return
        }
        try {
            await updateMonetization(this, { isPurchasableAsVoucher })
        } catch (error) {
            alertError(i18n.t('Failed to update Monetization is purchasable as voucher attribute'))
            logger.error(error)
        }
    }
    updatePurchaseDisabled = async (purchaseDisabled: boolean) => {
        // Handle that we change only purchasable monetizations
        if (!['transaction', 'subscription'].includes(this.firestoreData.type)) {
            return
        }
        try {
            await updateMonetization(this, { purchaseDisabled });
            (this.firestoreData as SubscriptionMonetizationDocument).purchaseDisabled = purchaseDisabled
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization purchase disabled attribute'))
            logger.error(e)
        }
    }

    updateSubscriptionIsHidden = async (subscriptionIsHidden: boolean) => {
        // Handle that we change only purchasable monetizations
        if (!['transaction', 'subscription'].includes(this.firestoreData.type)) {
            return
        }
        try {
            await updateMonetization(this, { subscriptionIsHidden });
            (this.firestoreData as SubscriptionMonetizationDocument).subscriptionIsHidden = subscriptionIsHidden
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization purchase listed attribute'))
            logger.error(e)
        }
    }

    addVariant = async (
        variant: MonetizationVariantField,
    ) => {
        try {
            // id and name will be set in initVariants after update
            await this.updateVariants([...this.variants, { ...variant, id: '', name: '' }])
        } catch (e) {
            alertError(i18n.t('Failed to create Monetization variant'))
            logger.error(e)
        }
    }

    removeVariant = async (
        variantId: string,
    ) => {
        try {
            const updatedVariants = this.variants.filter(variant => variant.id !== variantId)
            await this.updateVariants(updatedVariants)
        } catch (e) {
            alertError(i18n.t('Failed to remove Monetization variant'))
            logger.error(e)
        }
    }

    updateVariant = async (
        variant: ExtendedMonetizationVariant,
    ) => {
        try {
            const updatedVariants = this.variants

            let index
            if (variant.type === 'default') {
                index = updatedVariants.findIndex(v => v.type === 'default')
            } else {
                index = updatedVariants.findIndex(v => v.id === variant.id)
            }

            updatedVariants.splice(index, 1, variant)

            await this.updateVariants(updatedVariants)
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization variant'))
            logger.error(e)
        }
    }

    updateVariants = async (
        variants: ExtendedMonetizationVariant[],
    ) => {
        try {
            await updateMonetization(this, {
                variants: variants.map(convertToMonetizationVariant),
            })
        } catch (e) {
            alertError(i18n.t('Failed to update Monetization variants'))
            logger.error(e)
        }
    }

    /**
     * Updates ad slots of specified strategy of specified variant.
     *
     * @param variantId name of variant, whose slots will be updated
     * @param strategyName name of strategy (preRoll/midRoll/...), which will be updated
     * @param operation update operation
     */
    updateAdSlots = async (
        variantId: string,
        strategyName: StrategyName,
        operation: UpdateAdSlotsOperation,
    ) => {
        try {
            const variant = this.getVariantById(variantId) as ExtendedAdMonetizationVariant
            const strategyByName = variant?.strategies?.[strategyName]

            const currentAdSlots = strategyByName?.adSlots ?? []
            let updatedAdSlots
            const operationType = operation.type
            switch (operationType) {
                case 'add':
                    updatedAdSlots = [...currentAdSlots, ...operation.adSlots]
                    break
                case 'replace':
                    updatedAdSlots = operation.adSlots
                    break
                case 'delete':
                    updatedAdSlots = currentAdSlots
                        .filter((adSlot, index: number) => !operation.adSlotsIndicesToDelete.includes(index)) ?? []
                    break
                default:
                    throw new Error(`Unknown operation type ${operationType}`)

            }

            variant.strategies = {
                ...variant.strategies,
                [strategyName]: {
                    ...strategyByName,
                    adSlots: updatedAdSlots,
                },
            }

            if (!updatedAdSlots.length) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete variant.strategies[strategyName]
            }

            await this.updateVariant(variant)
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization strategy attribute'))
            logger.error(e)
        }
    }

    addAdSlot = async (
        variantId: string,
        name: StrategyName,
        adSlot: AdProfileDocumentWithId,
    ) => {
        await this.updateAdSlots(variantId, name, { type: 'add', adSlots: [adSlot] })
    }

    changeOrderOfAdSlots = async (
        variantId: string,
        name: StrategyName,
        adSlots: AdProfileDocumentWithId[],
    ) => {
        if (isEmpty(adSlots)) {
            return
        }
        await this.updateAdSlots(variantId, name, { type: 'replace', adSlots })
    }

    deleteAdSlot = async (
        variantId: string,
        name: StrategyName,
        deletedIndex: number,
    ) => {
        await this.updateAdSlots(variantId, name, { type: 'delete', adSlotsIndicesToDelete: [deletedIndex] })
    }

    deleteAdConfig = async (
        variantId: string,
        strategyName: StrategyName,
    ) => {
        await this.updateAdSlots(variantId, strategyName, { type: 'replace', adSlots: [] })
    }

    changeMidRollInterval = async (variantId: string, interval: number | string) => {
        try {
            const variant = this.getVariantById(variantId) as ExtendedAdMonetizationVariant
            if (!variant) {
                throw new Error(`Variant ${variantId} was not found in monetization ${this.getName}`)
            }

            const midRoll = variant.strategies?.midRoll
            const chapter = variant.strategies?.chapter

            let mergedStrategies = null
            if (interval === ON_EACH_CHAPTER) {
                mergedStrategies = {
                    ...this.getStrategies,
                    chapter: {
                        ...midRoll,
                        ...chapter,
                    },
                } as MonetizationAdVariantStrategiesField
                delete mergedStrategies.midRoll
            } else {
                mergedStrategies = {
                    ...this.getStrategies,
                    midRoll: {
                        ...midRoll,
                        ...chapter,
                        minWithoutAdMs: interval,
                    },
                } as MonetizationAdVariantStrategiesField
                delete mergedStrategies.chapter
            }

            variant.strategies = mergedStrategies

            await this.updateVariant(variant)
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization strategy attribute'))
            logger.error(e)
        }
    }

    updateMembershipRef = async (membershipRef: firebase.firestore.DocumentReference<MembershipDocument> | null) => {
        try {
            await updateMonetization(this, { membershipRef });
            (this.firestoreData as SubscriptionMonetizationDocument).membershipRef = membershipRef
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization membershipRef attribute'))
            logger.error(e)
        }
    }

    updateRecovery = async (recovery: MonetizationRecoveryField | undefined) => {
        try {
            if (recovery?.recoveryPaymentGatewayLink.length) {
                await updateMonetization(this, { recovery })
                // @ts-expect-error TODO only applicable for purchasable monetizations
                this.firestoreData.recovery = recovery
            } else {
                await this.ref.update({
                    recovery: firebase.firestore.FieldValue.delete(),
                })
                // @ts-expect-error TODO only applicable for purchasable monetizations
                this.firestoreData.recovery = undefined
            }
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization recovery field'))
            logger.error(e)
        }
    }

    /**
     * @param checkedTvChannels array of IDs
     * @param checkedVodChannels array of IDs
     * @param checkedSections array of IDs
     * @param checkedVideos array of IDs
     * @param tvStreamType
     */
    setMonetizationPlacement = async (
        checkedTvChannels: string[],
        checkedVodChannels: string[],
        checkedSections: string[],
        checkedVideos: string[],
        tvStreamType: TvStreamType,
    ) => {
        try {
            const updateObject: Partial<MonetizationDocument> = {
                placements: {
                    ...this.getPlacements,
                    tvChannelRefs: checkedTvChannels.map((tvChannel) => getFirestore().doc(`tvChannels/${tvChannel}`)),
                    channelRefs: checkedVodChannels.map((channel) => getFirestore().doc(`channels/${channel}`)),
                    sectionRefs: checkedSections.map((section) => getFirestore().doc(section)),
                    videoRefs: checkedVideos.map((video) => getFirestore().doc(`videos/${video}`)),
                    tvStreamType,
                },
            }

            await updateMonetization(this, updateObject)

            this.firestoreData.placements = updateObject.placements
            this.initPlacementItems()
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization strategy attribute'))
            logger.error(e)
        }
    }

    updateMonetizationTitle = async (
        title: string,
    ) => {
        try {
            this.setTitle = title

            await updateMonetization(this, {
                title,
            })
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization strategy attribute'))
            logger.error(e)
        }
    }

    updatePrices = async (variantId: string, prices: PricesField) => {
        try {
            const variant = this.getVariantById(variantId) as ExtendedPaidMonetizationVariant

            await this.updateVariant({ ...variant, prices })
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization prices attribute'))
            logger.error(e)
        }
    }

    updateFrequency = async (frequency?: MONETIZATION_FREQUENCY) => {
        try {
            await updateMonetization(this, { frequency });

            (this.firestoreData as SubscriptionMonetizationDocument).frequency = frequency
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization frequency attribute'))
            logger.error(e)
        }
    }

    updateDurationDays = async (durationDays?: MONETIZATION_DURATION) => {
        try {
            if (durationDays) {
                await updateMonetization(this, { durationDays });
                (this.firestoreData as SubscriptionMonetizationDocument).durationDays = durationDays
            } else {
                await this.ref.update({
                    durationDays: firebase.firestore.FieldValue.delete(),
                });
                (this.firestoreData as SubscriptionMonetizationDocument).durationDays = undefined
            }
        } catch (e) {
            alertError(i18n.t('Failed to update a Monetization duration attribute'))
            logger.error(e)
        }
    }

    // todo
    goMonetizationPage = () => {
        goMonetizationPage(this.getId)
    }

    set setTitle(title: string) {
        this.firestoreData.title = title
    }

    set setDescription(description: string) {
        this.firestoreData.description = description
    }

    set setFrequency(frequency: MONETIZATION_FREQUENCY) {
        (this.firestoreData as SubscriptionMonetizationDocument).frequency = frequency
    }

    set setMembershipRef(membershipRef: firebase.firestore.DocumentReference<MembershipDocument> | undefined) {
        (this.firestoreData as SubscriptionMonetizationDocument).membershipRef = membershipRef
    }

    get variants(): ExtendedMonetizationVariant[] {
        return this._variants
    }

    set variants(variants: ExtendedMonetizationVariant[]) {
        this._variants = variants
    }

    get defaultVariant(): ExtendedMonetizationVariant | undefined {
        return this.variants?.find((variant: MonetizationVariantField) => variant.type === 'default')
    }

    /**
     * Returns variant by id. As variant id is used variant.activatingMonetizationRef.id
     */
    getVariantById(id: string | undefined): ExtendedMonetizationVariant | undefined {
        if (!id || id === DEFAULT_VARIANT_ID) {
            return this.defaultVariant
        }
        return this.variants?.find(variant => variant.id === id)
    }
}

export default Monetization
