import { addOrReplace, formatCurrency } from '@tivio/common'
import { OrganizationMemberRoles, UserDocument } from '@tivio/firebase'
import { CustomerId, LangCode, MONETIZATION_FREQUENCY, VideoTranscodingStatus } from '@tivio/types'
import i18n from 'i18next'
import { uniqBy } from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'

import { getAsset } from '../components/asset/asset.utils'
import { createGlobalVideo } from '../creator/video.creator'
import { firebaseTimestampFromDate, getFirestore } from '../firebase/app'
import {
    createAdProfile,
    deleteAdProfile,
    getAdProfileById,
    getAdProfilesOfOrganization,
    updateAdProfile,
} from '../firebase/firestore/adProfile'
import { addApplication, getApplicationsByOrganization } from '../firebase/firestore/application'
import { addChannel, createAllVodChannelsPagination, createOrganizationChannelsPagination } from '../firebase/firestore/channel'
import { approveConsent, loadRequiredConsents } from '../firebase/firestore/consent'
import { createGlobalArticlesPagination, createGlobalPostsPagination } from '../firebase/firestore/content'
import { getEmailTemplates } from '../firebase/firestore/emailing'
import { addMembership } from '../firebase/firestore/membership'
import { addMonetization, removeMonetization } from '../firebase/firestore/monetization'
import { addTivioNotification, getNotificationById, getNotificationsByOrganization, sortNotifications } from '../firebase/firestore/notification'
import { getOrganizationRef, loadUser, loadUsers, updateOrganization } from '../firebase/firestore/organization'
import { addTivioScreen, getScreenById, getScreensByOrganization } from '../firebase/firestore/screen'
import { getGlobalAccessTags } from '../firebase/firestore/tag'
import { addTvChannel } from '../firebase/firestore/tvChannels'
import { createGlobalVideosPagination, getOrganizationVideoById } from '../firebase/firestore/video'
import { addWidget, removeWidgetById, WidgetFirestore } from '../firebase/firestore/widget'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { createEmptyArticlePagination, createEmptyChannelPagination, createEmptyPostsPagination, createEmptyVideoPagination } from '../utils/pagination.utils'

import Channel from './Channel'
import { DataState } from './common/DataState'
import Deploy from './Deploy'
import { Membership } from './Membership'
import TvChannel from './TvChannel'
import { TvProgram } from './TvProgram'
import User from './User'
import Widget from './Widget'

import type { AdProfileFirestoreForForm } from '../firebase/firestore/adProfile'
import type { AdProfileDocumentWithId } from '../firebase/firestore/converters'
import type { AssetResizeResult } from '../firebase/firestore/types'
import type { RefsPagination } from '../firebase/pagination'
import type { Application } from './Application'
import type { Article } from './Article'
import type { AssetPreset } from './AssetPreset'
import type { Consent } from './Consent'
import type Monetization from './Monetization'
import type { TivioNotification } from './Notification'
import type { Post } from './Post'
import type { Tag } from './Tag'
import type { TagType } from './TagType'
import type { Template } from './Template'
import type { TivioScreen } from './TivioScreen'
import type Video from './Video'
import type {
    AdMonetizationDocument,
    ApplicationDocumentCreation,
    ArticleDocument,
    ChannelDocument,
    ContentDocument,
    MembershipDocument,
    MonetizationDocument,
    MonetizationVariantField,
    NotificationDocument,
    OrganizationCompanyInfoField,
    OrganizationDocument,
    OrganizationEpgConfigurationField,
    OrganizationMembersField,
    ProfileDocument,
    PromotionDocument,
    QueryDocumentSnapshot,
    ScreenDocumentCreation,
    StrategyName,
    TvChannelDocument,
    VideoDocument,
} from '@tivio/firebase'
import type {
    AssetsField,
    Currency,
    Disposer,
    DurationWithUnit,
    LoadScreenOptions,
    ScalableAsset,
    Scale,
    TvStreamType,
} from '@tivio/types'
import type firebase from 'firebase/app'


const logger = new Logger('Organization')

class Organization {
    private _widgets: Widget[] = []
    private _tvChannels: TvChannel[] = []
    private _tvPrograms: TvProgram[] = []
    private _memberships: Membership[] = []
    private _monetizations: Monetization[] = []
    private _channelsPagination: RefsPagination<ChannelDocument, Channel>
    private _allChannelsPagination: RefsPagination<ChannelDocument, Channel>
    private _videosPagination: RefsPagination<VideoDocument, Video>
    private _articlesPagination: RefsPagination<ArticleDocument, Article>
    private _postsPagination: RefsPagination<ContentDocument, Post>
    private _assetPresets: AssetPreset[] = []
    private _disposers: Disposer[] = []
    private _screensState: DataState<TivioScreen[]> = new DataState<TivioScreen[]>([])
    private _notificationsState: DataState<TivioNotification[]> = new DataState<TivioNotification[]>([])
    private _applicationsState: DataState<Application[]> = new DataState<Application[]>([])
    private _tagTypes: TagType[] = []
    public deploy: Deploy
    private _lastUserSearch = ''
    private _lastVideoSearch = ''
    /**
     * Document id - user map.
     */
    private _users: Map<string, User> = new Map()
    private _languages: LangCode[]
    private _approvedConsentOrgIds: string[] | null = null
    private _requiredConsents: Consent[] | null = null
    private _epgChannelAccessTag: Tag | null = null
    private _emailTemplates: Template[] | null = null

    constructor(
        private _ref: firebase.firestore.DocumentReference<OrganizationDocument>,
        private _firestoreData: OrganizationDocument,
    ) {
        this.deploy = new Deploy(this)

        this._allChannelsPagination = createEmptyChannelPagination(this)
        this._channelsPagination = createEmptyChannelPagination(this)
        this._videosPagination = createEmptyVideoPagination(this)
        this._articlesPagination = createEmptyArticlePagination(this)
        this._postsPagination = createEmptyPostsPagination(this)

        this._languages = _firestoreData.languages && _firestoreData.languages.length > 0 ? _firestoreData.languages : [LangCode.CS]

        makeAutoObservable(this, {
            updateItemHeightCoefficient: action,
        })
    }

    /**
     * Loads all screens for this Organization.
     *
     * @param options additional options
     */
    loadScreens = async (options?: LoadScreenOptions): Promise<void> => {
        await this._screensState.loadData(
            () => getScreensByOrganization(this),
            async (screens) => {
                if (options?.loadRows) {
                    await Promise.all(screens.map(screen => screen.loadRows()))
                }
            },
        )
    }

    /**
     * Loads all applications for this Organization.
     */
    loadApplications = async (): Promise<void> => {
        await this._applicationsState.loadData(
            () => getApplicationsByOrganization(this),
        )
    }

    /**
     * Loads screen of this Organization by given id (even if screen with this id was already loaded)
     *
     * @param id screen document id
     * @param options additional options
     */
    loadScreenById = async (id: string, options?: LoadScreenOptions): Promise<void> => {
        let screen: TivioScreen
        await this._screensState.loadData(
            async () => {
                screen = await getScreenById(this, id)

                // only one item is loaded, but loadDataFunction should return array of items.
                // we don't want to delete previously loaded items, just update one concrete item in this store (or add it, if not yet exist there)
                // TODO consider to solve it in DataState. Maybe create ArrayDataState (extending DataState) that will have extra method "loadItem"
                return addOrReplace([...this.screens], screen, (s1: TivioScreen, s2: TivioScreen) => s1.id === s2.id)
            },
            async () => {
                if (options?.loadRows) {
                    await screen?.loadRows()
                }
            },
        )
    }

    /**
     * Returns loaded screen by given id.
     * Don't load screen if was not loaded yet, for loading use {@link loadScreens} or {@link loadScreenById}.
     *
     * @param id screen document id
     */
    getScreenById = (id: string) => {
        return this.screens.find(screen => screen.id === id)
    }

    addScreen = async (screenData: ScreenDocumentCreation) => {
        const screen = await addTivioScreen(this, screenData)

        this._screensState.data = this._screensState.data.concat([screen])
    }

    removeScreen = async (screen: TivioScreen) => {
        await screen.ref.delete()
        this._screensState.data = this._screensState.data.filter(filterScreen => filterScreen.id !== screen.id)
    }

    addApplication = async (applicationData: ApplicationDocumentCreation) => {
        const application = await addApplication(this, applicationData)
        this._applicationsState.data = this._applicationsState.data.concat([application])
    }

    removeApplication = async (application: Application) => {
        await application.ref.delete()
        this._applicationsState.data = this._applicationsState.data.filter(filterApplication => filterApplication.id !== application.id)
    }

    /**
     * Loads all notifications for this Organization.
     */
    loadNotifications = async (): Promise<void> => {
        await this._notificationsState.loadData(
            () => getNotificationsByOrganization(this),
        )
    }

    /**
     * Loads notification of this Organization by given id (even if notification with this id was already loaded)
     *
     * @param id notification document id
     */
    loadNotificationById = async (id: string): Promise<void> => {
        let notification: TivioNotification
        await this._notificationsState.loadData(
            async () => {
                notification = await getNotificationById(this, id)

                // only one item is loaded, but loadDataFunction should return array of items.
                // we don't want to delete previously loaded items, just update one concrete item in this store (or add it, if not yet exist there)
                return addOrReplace([...this.notifications], notification, (s1: TivioNotification, s2: TivioNotification) => s1.id === s2.id)
            },
        )
    }

    /**
     * Returns loaded notification by given id.
     * Don't load notification if was not loaded yet, for loading use {@link loadNotifications} or {@link loadNotificationById}.
     *
     * @param id notification document id
     */
    getNotificationById = (id: string) => {
        return this.notifications.find(notification => notification.id === id)
    }

    addNotification = async (notificationData: NotificationDocument) => {
        const notification = await addTivioNotification(this, notificationData)

        this._notificationsState.data = this._notificationsState.data.concat([notification]).sort(sortNotifications)
        return notification
    }

    removeNotification = async (notification: TivioNotification) => {
        await notification.ref.delete()
        this._notificationsState.data = this._notificationsState.data.filter(filterNotification => filterNotification.id !== notification.id)
    }

    addVideo = (video: Video) => {
        this._videosPagination.addItemToStart(video, video.getRef)
    }

    addArticle = (article: Article) => {
        this._articlesPagination.addItemToStart(article, article.ref)
    }

    deleteVideo = async (video: Video) => {
        const deleteSuccess = await video.delete()
        if (deleteSuccess) {
            this._videosPagination.setItems = this._videosPagination.getItems.filter(filterVideo => filterVideo.id !== video.id)
        }

        return deleteSuccess
    }

    deleteArticle = async (article: Article) => {
        await article.delete()
        this._articlesPagination.setItems = this._articlesPagination.getItems.filter(filterArticle => filterArticle.id !== article.id)
        this._postsPagination.setItems = this._postsPagination.getItems.filter(filterPost => filterPost.id !== article.id)
    }

    initVideosPagination = async () => {
        if (this._videosPagination.isInitialized) {
            return
        }

        logger.info('going to init globals videos pagination')

        return createGlobalVideosPagination(this)
    }

    initAllChannelsPagination = async () => {
        if (this._allChannelsPagination.isInitialized) {
            return
        }

        logger.info('going to init all channels pagination')

        return createAllVodChannelsPagination(this)
    }

    initOrganizationChannelsPagination = () => {
        if (this._channelsPagination.isInitialized) {
            return
        }

        logger.info('going to init organization channels pagination')

        return createOrganizationChannelsPagination(this)
    }

    initArticlesPagination = () => {
        if (this._articlesPagination.isInitialized) {
            return
        }

        logger.info('going to init articles pagination')

        return createGlobalArticlesPagination(this)
    }

    initPostsPagination = () => {
        if (this._postsPagination.isInitialized) {
            return
        }

        logger.info('going to init posts pagination')

        return createGlobalPostsPagination(this)
    }

    initOrganizationConsents = async (memberUid: string) => {
        const memberGroups = this.getMemberGroupsByRole
        const adminUids = Object.keys(memberGroups[OrganizationMemberRoles.ADMIN] ?? [])

        let shouldRequireConsents = true

        if ((adminUids.length && !adminUids.includes(memberUid)) || Object.keys(memberGroups[OrganizationMemberRoles.SUPER_ADMIN]).includes(memberUid)) {
            shouldRequireConsents = false
        }

        logger.info('going to init organization required consents')

        return loadRequiredConsents(this, shouldRequireConsents)
    }

    initOrganizationEpgChannelAccessTag = async () => {
        const globalDistributionTags = await getGlobalAccessTags()
        const organizationDistributionTag = globalDistributionTags.find((
            { metadata },
        ) => {
            return metadata.some((
                { key, type, value },
            ) => {
                return key === 'organizationRef' && type === 'ORGANIZATION_REF' && value.path === this._ref.path
            })
        })

        this.epgChannelAccessTag = organizationDistributionTag ?? null
    }

    initOrganizationEmailTemplates = async () => {
        const emailTemplates = await getEmailTemplates(this.ref)
        this.emailTemplates = emailTemplates
    }

    get getMemberGroupsByRole() {
        return Object.entries(this.firestoreData.members).reduce((acc, [uid, member]) => {
            return {
                ...acc,
                [member.role]: {
                    ...acc[member.role],
                    [uid]: member,
                },
            }
        }, {} as Record<OrganizationMemberRoles, Record<string, OrganizationMembersField>>)
    }

    async addTvChannel(tvChannelData: TvChannelDocument) {
        try {
            const tvChannelRef = await addTvChannel(tvChannelData)
            const tvChannel = new TvChannel(tvChannelRef as firebase.firestore.DocumentReference<any>, tvChannelData)

            this.tvChannels = this.tvChannels.concat([tvChannel])
            await updateOrganization(this, { tvChannels: this.tvChannels.map(tvChannel => tvChannel.getRef) })
        } catch (e) {
            console.error(e)
        }
    }

    async addSecret(secret: string) {
        try {
            const secrets = this.secrets.concat(secret)
            this.secrets = secrets
            await updateOrganization(this, { secrets })
        } catch (e) {
            console.error(e)
        }
    }

    async addChannel(channelData: ChannelDocument): Promise<void> {
        try {
            const channelRef = await addChannel(channelData)

            const channelsRefs = this.channelsRefs.concat(channelRef)
            await this.updateChannels(channelsRefs)

            this.channelsRefs = channelsRefs
            this.channelsPagination.addItem(
                new Channel(channelRef, channelData, this),
                channelRef,
            )

            alertSuccess(i18n.t('Channel created'))
        } catch (e) {
            alertError(i18n.t('Failed to create channel'))
            logger.error(e)
        }
    }

    async addWidget(widgetData: WidgetFirestore): Promise<void> {
        try {
            const widgetRef = await addWidget(widgetData)
            const widget = new Widget(widgetRef, widgetData, this)
            const widgetsRefs = this.widgets.map(widget => widget.getRef).concat(widgetRef)

            await this.updateWidgets(widgetsRefs)

            this.widgets = this.widgets.concat(widget)
            this.widgetsRefs = widgetsRefs

            alertSuccess(i18n.t('Widget created'))
        } catch (e) {
            alertError(i18n.t('Failed to create Widget'))
            logger.error(e)
        }
    }

    async removeWidget(id: string): Promise<void> {
        try {
            const widgets = this.widgets.filter((widget) => widget.getId !== id)
            const widgetsRefs = this._widgets.map(widget => widget.getRef)

            await this.updateWidgets(widgetsRefs)
            await removeWidgetById(id)

            this.widgets = widgets
            this.widgetsRefs = widgetsRefs
        } catch (e) {
            alertError(i18n.t('Failed to create Widget'))
            logger.error(e)
        }
    }

    async addMonetization(data: Partial<MonetizationDocument>) {
        try {
            const monetizationData = {
                organizationRef: this._ref,
                title: '',
                description: '',
                created: firebaseTimestampFromDate(),
                placements: {},
                ...data,
            } as MonetizationDocument

            await addMonetization(monetizationData)
        } catch (e) {
            alertError(i18n.t('Failed to create Monetization'))
            logger.error(e)
        }
    }

    async removeMonetization(id: string) {
        try {
            const monetization = this.monetizations.find((monetization) => monetization.getId === id)
            if (!monetization) {
                alertError(i18n.t('Monetization doesn\'t exists'))
                return
            }

            await removeMonetization(id)
        } catch (e) {
            alertError(i18n.t('Failed to remove Monetization'))
            logger.error(`Failed to remove monetization (id: ${id})`, e)
        }
    }

    async addAd(data: Partial<AdMonetizationDocument>): Promise<void> {
        await this.addMonetization({ ...data, type: 'advertisement' })
        alertSuccess(i18n.t('Advertisement Strategy has been added'))
    }

    async addMonetizationVariant(
        originalMonetizationId: string,
        activatingMonetizationId: string,
        originalMonetizationType: 'transaction' | 'advertisement' | 'subscription',
        duration?: DurationWithUnit,
    ) {
        const activatingMonetization = this.findLoadedMonetization(activatingMonetizationId)

        const variant = {
            activatingMonetizationRef: activatingMonetization?.getRef,
            ...(duration ? { activatingDuration: duration } : {}),
            ...(originalMonetizationType === 'advertisement' ? { strategies: {} } : { prices: {} }),
            type: 'variant',
        } as MonetizationVariantField


        await this
            .findLoadedMonetization(originalMonetizationId)
            .addVariant(variant)

        alertSuccess(i18n.t('Monetization variant has been added'))
    }

    async removeMonetizationVariant(monetizationId: string, variantId: string) {
        await this
            .findLoadedMonetization(monetizationId)
            .removeVariant(variantId)

        alertSuccess(i18n.t('Monetization variant has been removed'))
    }

    async addAdSlot(
        monetizationId: string,
        variantId: string,
        strategyName: StrategyName,
        adSlot: AdProfileDocumentWithId,
    ) {
        await this
            .findLoadedMonetization(monetizationId)
            .addAdSlot(variantId, strategyName, adSlot)

        alertSuccess(i18n.t('Monetization ad profile has been added'))
    }

    async changeOrderOfAdSlots(
        monetizationId: string,
        variantId: string,
        strategyName: StrategyName,
        adSlots: AdProfileDocumentWithId[],
    ) {
        await this
            .findLoadedMonetization(monetizationId)
            .changeOrderOfAdSlots(variantId, strategyName, adSlots)

        alertSuccess(i18n.t('Monetization ad profiles have been reordered'))
    }

    async deleteAdSlot(monetizationId: string, variantId: string, strategyName: StrategyName, index: number) {
        await this
            .findLoadedMonetization(monetizationId)
            .deleteAdSlot(variantId, strategyName, index)

        alertSuccess(i18n.t('Monetization ad profile has been deleted'))
    }

    async deleteAdConfig(monetizationId: string, variantId: string, name: StrategyName) {
        await this
            .findLoadedMonetization(monetizationId)
            .deleteAdConfig(variantId, name)

        alertSuccess(i18n.t('Monetization ad profiles have been deleted'))
    }

    // TODO what is this doing here? ... why are all the monetization-related
    // methods here on the organization class? Don't hey belong somewhere else?
    // like not on the organization class?
    async changeMidRollInterval(monetizationId: string, variantId: string, interval: number | string) {
        await this
            .findLoadedMonetization(monetizationId)
            .changeMidRollInterval(variantId, interval)

        alertSuccess(i18n.t('Mid-roll interval has been updated'))
    }

    /**
     * @param checkedTvChannels array of IDs
     * @param checkedVodChannels array of IDs
     * @param checkedSections array of IDs
     * @param checkedVideos array of IDs
     * @param tvStreamType
     */
    async setMonetizationPlacement(
        monetizationId: string,
        checkedTvChannels: string[],
        checkedVodChannels: string[],
        checkedSections: string[],
        checkedVideos: string[],
        tvStream: TvStreamType,
    ) {
        await this
            .findLoadedMonetization(monetizationId)
            .setMonetizationPlacement(checkedTvChannels, checkedVodChannels, checkedSections, checkedVideos, tvStream)

        alertSuccess(i18n.t('Monetization placement has been updated'))
    }

    async updateMonetizationTitle(
        monetizationId: string,
        title: string,
    ) {
        await this
            .findLoadedMonetization(monetizationId)
            .updateMonetizationTitle(title)

        alertSuccess(i18n.t('Monetization placement has been updated'))
    }

    findLoadedMonetization(monetizationId: string) {
        // TODO: This is a bug. Could also return undefined.
        return this.monetizations.find((monetization) => monetization.getId === monetizationId) as Monetization
    }

    async addMembership(organization: Organization, membershipData: Partial<MembershipDocument>) {
        try {
            const data: MembershipDocument = {
                name: '',
                created: firebaseTimestampFromDate(),
                ...membershipData,
            }

            const membershipRef = await addMembership(organization, data)

            this.memberships = [
                ...this.memberships,
                new Membership(membershipRef, data),
            ]

            alertSuccess(i18n.t('Membership has been added'))
            return membershipRef
        } catch (e) {
            alertError(i18n.t('Failed to create Membership'))
            logger.error(e)
        }
    }

    async removeMembership(organization: Organization, id: string): Promise<void> {
        try {
            const membership = this.memberships.find((m) => m.getId === id)
            if (!membership) {
                alertError(i18n.t('Membership doesn\'t exists'))
                return
            }

            const memberships = this.memberships.filter((m) => m.getId !== id)
            await membership.delete()

            this.memberships = memberships
        } catch (e) {
            alertError(i18n.t('Failed to remove Membership'))
            logger.error(e)
        }
    }

    async adSubscription(data: Partial<MonetizationDocument>): Promise<void> {
        await this.addMonetization({
            ...data,
            frequency: MONETIZATION_FREQUENCY.MONTHLY,
            type: 'subscription',
            subscriptionIsHidden: true,
            variants: [
                { type: 'default', prices: {} },
            ],
        })
        alertSuccess(i18n.t('Advertisement Subscription has been added'))
    }

    async addTransaction(data: Partial<MonetizationDocument>): Promise<void> {
        await this.addMonetization({
            ...data,
            frequency: MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT,
            type: 'transaction',
            variants: [
                { type: 'default', prices: {} },
            ],
        })
        alertSuccess(i18n.t('Advertisement Purchase has been added'))
    }

    async addAdProfile(data: AdProfileFirestoreForForm) {
        try {
            // @ts-expect-error TODO type
            await createAdProfile({
                ...data,
                organizationRef: this._ref,
            })

            alertSuccess(i18n.t('Monetization Profile has been added'))
        } catch (e) {
            alertError(i18n.t('Failed to create Monetization Profile'))
            logger.error(e)
        }
    }

    async updateAdProfile(id: string, data: AdProfileFirestoreForForm) {
        try {
            await updateAdProfile(id, data)

            alertSuccess(i18n.t('Monetization Profile has been added'))
        } catch (e) {
            alertError(i18n.t('Failed to create Monetization Profile'))
            logger.error(e)
        }
    }

    getAdProfiles() {
        return getAdProfilesOfOrganization(this._ref)
    }

    getAdProfile(id: string): Promise<AdProfileDocumentWithId> {
        return getAdProfileById(id)
    }

    deleteAdProfile(id: string) {
        return deleteAdProfile(id)
    }

    private updateChannels = async (channelsRefs: firebase.firestore.DocumentReference<ChannelDocument>[]) => {
        return updateOrganization(this, { channels: channelsRefs })
    }

    private updateWidgets = async (widgetsRefs: firebase.firestore.DocumentReference<WidgetFirestore>[]) => {
        return updateOrganization(this, { widgets: widgetsRefs })
    }

    get drm() {
        return this._firestoreData.drm
    }

    get epgConfiguration() {
        return this._firestoreData.epgConfiguration
    }

    /**
     * Update item height coefficient for EPG Editor configuration.
     *
     * @param itemHeightCoefficient item height coefficient
     */
    async updateItemHeightCoefficient(itemHeightCoefficient: number) {
        const epgConfiguration = this.epgConfiguration

        const newEpgConfiguration: OrganizationEpgConfigurationField = {
            ...epgConfiguration,
            editor: {
                ...epgConfiguration?.editor,
                itemHeightCoefficient,
            },
        }

        this._firestoreData.epgConfiguration = newEpgConfiguration

        return updateOrganization(this, { epgConfiguration: newEpgConfiguration })
    }

    get applications() {
        return this._applicationsState.data
    }

    get globalVideosPagination() {
        return this._videosPagination
    }

    get globalArticlesPagination() {
        return this._articlesPagination
    }

    get globalPostsPagination() {
        return this._postsPagination
    }

    get channelsRefs() {
        return this._firestoreData.channels
    }

    get widgets() {
        return this._widgets
    }

    get memberships() {
        return this._memberships
    }

    get monetizations() {
        return this._monetizations
    }

    get assetPresets() {
        return this._assetPresets
    }

    get ads() {
        return this._monetizations.filter((row) => row.getType === 'advertisement')
    }

    get transactions() {
        return this._monetizations.filter((row) => row.getType === 'transaction')
    }

    get subscriptions() {
        return this._monetizations.filter((row) => row.getType === 'subscription')
    }

    get promotions() {
        return this._firestoreData.promotions
    }

    get widgetsRefs() {
        // @ts-expect-error
        return this._firestoreData.widgets
    }

    get secrets() {
        return this._firestoreData.secrets ?? []
    }

    get tvChannelsRefs() {
        return this._firestoreData.tvChannels
    }

    get id() {
        return this._ref.id
    }

    get name() {
        return this._firestoreData.name
    }

    get description() {
        return this._firestoreData.description
    }

    get members() {
        return this._firestoreData.members
    }

    get ref() {
        return this._ref
    }

    get firestoreData() {
        return this._firestoreData
    }

    get tvChannels() {
        return this._tvChannels
    }

    get tvPrograms() {
        return this._tvPrograms
    }

    get channelsPagination() {
        return this._channelsPagination
    }

    get getAssets() {
        return this._firestoreData.assets
    }

    set setAssets(assets: AssetsField) {
        this._firestoreData.assets = assets

        updateOrganization(this, {
            assets: assets,
        })
    }

    get epgChannelAccessTag() {
        return this._epgChannelAccessTag
    }

    set epgChannelAccessTag(tag: Tag | null) {
        this._epgChannelAccessTag = tag
    }

    saveAssetResizeResults(resizeResults: AssetResizeResult[], name: string) {
        // we always expect at least '@1' scale here
        const scalableAsset = resizeResults.reduce((resultObject, resizeResult) => ({
            ...resultObject,
            [resizeResult.scale]: {
                background: resizeResult.url,
            },
        }), {}) as ScalableAsset

        this.setAssets = { ...this.getAssets, [name]: scalableAsset }
    }

    getAsset = (presetName: string, scale?: Scale) => {
        return getAsset(
            this.getAssets ?? {},
            presetName,
            scale,
        )
    }

    /**
     * Returns loaded screens
     */
    get screens() {
        return this.screensState.data
    }

    /**
     * Returns screens state (data, errors, loading)
     */
    get screensState() {
        return this._screensState
    }

    /**
     * Returns applications state (data, errors, loading)
     */
    get applicationsState() {
        return this._applicationsState
    }

    /**
     * Returns loaded notifications
     */
    get notifications() {
        return this.notificationsState.data
    }

    /**
     * Returns notifications state (data, errors, loading)
     */
    get notificationsState() {
        return this._notificationsState
    }

    get tagTypes() {
        return this._tagTypes
    }

    set tagTypes(tagTypes: TagType[]) {
        this._tagTypes = tagTypes
    }

    get allChannelsPagination() {
        return this._allChannelsPagination
    }

    set channelsRefs(channelsRefs: firebase.firestore.DocumentReference<ChannelDocument>[]) {
        this._firestoreData.channels = channelsRefs
    }

    set tvChannels(tvChannels: TvChannel[]) {
        this._tvChannels = tvChannels
    }

    set tvPrograms(tvPrograms: TvProgram[]) {
        this._tvPrograms = tvPrograms
    }

    set widgets(widgets: Widget[]) {
        this._widgets = widgets
    }

    set widgetsRefs(widgetsRefs: firebase.firestore.DocumentReference<WidgetFirestore>[]) {
        this._firestoreData.widgets = widgetsRefs
    }

    set memberships(memberships: Membership[]) {
        this._memberships = memberships
    }

    set monetizations(monetizations: Monetization[]) {
        this._monetizations = monetizations
        monetizations.forEach(mon => mon.initVariants())
    }

    set secrets(secrets: string[]) {
        this._firestoreData.secrets = secrets
    }

    get path() {
        return this._ref.path
    }

    get requiresConsent() {
        return Boolean(this.requiredConsentOrgRefs &&
            this.requiredConsentOrgRefs.length > 0 &&
            this.requiredConsents && this.requiredConsents.length > 0)
    }

    get requiredConsentOrgRefs() {
        return this._firestoreData.requiredConsentOrgRefs
    }

    get emailConfigs() {
        return this._firestoreData.emailConfig
    }

    get userProfileConfiguration() {
        return this._firestoreData.userProfileConfiguration
    }

    set channelsPagination(channelsPagination: RefsPagination<ChannelDocument, Channel>) {
        this._channelsPagination = channelsPagination
    }

    set allChannelsPagination(channelsPagination: RefsPagination<ChannelDocument, Channel>) {
        this._allChannelsPagination = channelsPagination
    }

    set globalVideosPagination(videosPagination: RefsPagination<VideoDocument, Video>) {
        this._videosPagination = videosPagination
    }

    set globalArticlesPagination(articlesPagination: RefsPagination<ArticleDocument, Article>) {
        this._articlesPagination = articlesPagination
    }

    set globalPostsPagination(postsPagination: RefsPagination<ContentDocument, Post>) {
        this._postsPagination = postsPagination
    }

    set assetPresets(presets: AssetPreset[]) {
        this._assetPresets = presets
    }

    get approvedConsentOrgIds() {
        return this._approvedConsentOrgIds
    }

    get requiredConsents() {
        return this._requiredConsents
    }

    get emailTemplates() {
        return this._emailTemplates
    }

    set approvedConsentOrgIds(approvedConsentOrgIds: string[] | null) {
        this._approvedConsentOrgIds = approvedConsentOrgIds
    }

    set requiredConsents(requiredConsents: Consent[] | null) {
        this._requiredConsents = requiredConsents
    }

    get companyInfo() {
        return this._firestoreData.companyInfo
    }

    get discordGuildId() {
        return this._firestoreData?.integrations?.discord?.guildId
    }

    updateCompanyInfo = async (companyInfo: OrganizationCompanyInfoField) => {
        await updateOrganization(this, { companyInfo })

        runInAction(() => {
            this._firestoreData.companyInfo = companyInfo
        })
    }

    set emailTemplates(emailTemplates: Template[] | null) {
        this._emailTemplates = emailTemplates
    }

    async addRequiredConsentFromOrgId(orgId: string) {
        const orgRef = getOrganizationRef(orgId)
        const newRequiredConsentOrgRefs = uniqBy([...(this.requiredConsentOrgRefs ?? []), orgRef], ref => ref.id)
        await updateOrganization(this, {
            requiredConsentOrgRefs: newRequiredConsentOrgRefs,
        })
        runInAction(() => {
            this._firestoreData.requiredConsentOrgRefs = newRequiredConsentOrgRefs
        })
    }

    updateField = async <T extends keyof OrganizationDocument>(field: T, value: OrganizationDocument[T]) => {
        await updateOrganization(this, { [field]: value })

        runInAction(() => {
            this._firestoreData[field] = value
        })
    }

    addDisposer(disposer: Disposer) {
        this._disposers.push(disposer)
    }

    dispose() {
        this._disposers.forEach(disposer => disposer())
        this._disposers = []
    }

    getVideoById = async (videoId: string) => {
        // Check if video is already loaded
        const findVideo = this.globalVideosPagination.getItems.find(findVideo => findVideo.getId === videoId)

        if (!findVideo) {
            const video = await getOrganizationVideoById(videoId, this._ref)

            if (!video) {
                return
            }

            return createGlobalVideo(video.ref, video.data, this)
        } else {
            return findVideo
        }
    }

    filterVideoForEpg = async (videoPromise: Promise<Video | undefined>): Promise<Video | null> => {
        return videoPromise.then(async (video) => {
            if (video === undefined) {
                return null
            }

            const encodingProfiles = await this.getEncodingProfiles()

            let isVideoEncoded = false

            if (encodingProfiles !== null && video.data.profiles !== undefined) {
                const encodingProfilesIds = encodingProfiles.map(encodingProfile => encodingProfile.id)

                isVideoEncoded = encodingProfilesIds.every(profileId => Object.keys(video.data.profiles!).includes(profileId))
            }

            if (video.data.transcodingStatus === VideoTranscodingStatus.DONE || isVideoEncoded) {
                return video
            }

            return null
        })
    }

    getPromotions = async () => {
        const promotions = (await getFirestore()
            .collection(`/organizations/${this.id}/promotions`)
            .get()).docs

        return promotions.map((promo) => {
            const data = promo.data()

            return {
                id: promo.id,
                ...data,
            }
        }) as PromotionDocument[]
    }

    /**
     * Creates new {@link User} entity, and saves it to organization entity cache.
     * DOES NOT CREATE NEW USER IN DB.
     *
     * @param ref - user document reference
     * @param data - user document data
     */
    newUserEntity = (
        ref: firebase.firestore.DocumentReference<UserDocument>,
        data: UserDocument,
    ): User => {
        const user = new User(ref, data, this)
        this._users.set(ref.id, user)
        return user
    }

    getUserById = async (userId: string): Promise<User | undefined> => {
        const findUser = this._users.get(userId)

        if (findUser) {
            return findUser // Already loaded
        }

        try {
            const user = await loadUser(userId, this)
            runInAction(() => {
                if (user) {
                    this._users.set(userId, user) // cache user for future use
                }
            })

            return user
        } catch (e) {
            alertError(i18n.t('Failed to load users'))
            logger.error(e)
        }
    }

    getUsersByEmail = async (email: string): Promise<User[] | undefined> => {
        return (await loadUsers(this, email))?.filter(u => u.email)
    }

    getEncodingProfiles = async (): Promise<QueryDocumentSnapshot<ProfileDocument>[] | null> => {
        const profile = (await getFirestore()
            .collection('profiles')
            .where('organizationRef', '==', this._ref)
            .get())
            .docs

        if (profile.length > 0) {
            return profile as QueryDocumentSnapshot<ProfileDocument>[]
        }

        return null
    }

    /**
     * Last search query in Videos page. Use it for saving search phrase in between navigation changes.
     */
    get lastVideoSearch() {
        return this._lastVideoSearch
    }

    set lastVideoSearch(lastVideoSearch: string) {
        this._lastVideoSearch = lastVideoSearch
    }

    /**
     * Last search query in Users page. Use it for saving search phrase in between navigation changes.
     */
    get lastUserSearch() {
        return this._lastUserSearch
    }

    set lastUserSearch(lastUserSearch: string) {
        this._lastUserSearch = lastUserSearch
    }

    async updateLanguages(languages: LangCode[]) {
        await updateOrganization(this, { languages, defaultLanguage: languages[0] })

        runInAction(() => {
            this._languages = languages
        })
    }

    async updateCurrencies(currencies: Currency[]) {
        await updateOrganization(this, { currencies })

        runInAction(() => {
            this._firestoreData.currencies = currencies
        })
    }

    get languages() {
        return this._languages
    }

    get currencies() {
        return this._firestoreData.currencies ?? [this._firestoreData.overrideCurrency ?? 'CZK']
    }

    get customerId() {
        return this._firestoreData.customerId
    }

    get currency(): Currency {
        // TODO Temporary solution to DEV meeting where we decide how to handle it
        const CURRENCY_MAP: { [customerId in CustomerId]?: Currency } = {
            [CustomerId.JOJ]: 'EUR',
            [CustomerId.GRAPE]: 'CZK',
            [CustomerId.DVTV]: 'CZK',
            [CustomerId.GARAZ]: 'EUR',
            [CustomerId.OKTAGON]: 'EUR',
            [CustomerId.DVTV_DEV]: 'CZK',
            [CustomerId.INVESTOREES]: 'CZK',
        }
        if (this.customerId) {
            return CURRENCY_MAP[this.customerId] ?? 'CZK'
        }
        return 'CZK'
    }

    get currencySymbol() {
        return formatCurrency(this.currency)
    }

    approveConsent = async (consentId: string) => {
        const result = await approveConsent(this, consentId)

        runInAction(() => {
            if (this._requiredConsents) {
                this.requiredConsents = this._requiredConsents.filter(consent => consent.id !== consentId)

                const consentOrgId = this._requiredConsents.find(consent => consent.id === consentId)?.organizationRef.id
                if (consentOrgId) {
                    this.approvedConsentOrgIds = [...(this.approvedConsentOrgIds ?? []), consentOrgId]
                }
            }
        })

        return result
    }

    getHasApprovedConsent = (consentOrgId: string) => {
        return this.approvedConsentOrgIds?.includes(consentOrgId) ?? false
    }
}

export default Organization
