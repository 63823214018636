import { LangCode, Translation } from '@tivio/types'
import i18n from 'i18next'


export const translate = (text: string) => {
    return i18n.t(text)
}

export const mapLangCodeToLanguage = (langCode: LangCode) => {
    switch (langCode) {
        case LangCode.CS:
            return 'Czech'
        case LangCode.EN:
            return 'English'
        case LangCode.ES:
            return 'Spanish'
        case LangCode.DE:
            return 'German'
        case LangCode.FR:
            return 'French'
        case LangCode.IT:
            return 'Italian'
        case LangCode.PL:
            return 'Polish'
        case LangCode.PT:
            return 'Portuguese'
        case LangCode.SK:
            return 'Slovak'
        case LangCode.HU:
            return 'Hungarian'
        default:
            return langCode
    }
}

const getTranslationFromObject = (translation: Translation, langCodes: LangCode[]) => {
    for (const langCode of langCodes) {
        if (translation[langCode]) {
            return translation[langCode]
        }
    }
}

export const getTranslation = (translation: Translation | string, defaultLangCodes = [LangCode.EN]) => {
    switch (typeof translation) {
        // TODO (TIV-2029): remove once we migrate all documents to new format (object with translations)
        case 'string':
            return translation
        case 'object':
            return getTranslationFromObject(translation, defaultLangCodes) || translation[Object.keys(translation)[0] as LangCode] || ''
        default:
            return ''
    }
}
