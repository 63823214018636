import { ALGOLIA_INDEX_NAME, PaginationInterface } from '@tivio/types'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

import {
    DEFAULT_SEARCH_OPTIONS,
    getEpgAccessTagFilters,
    getExternalAndOrganizationFilters,
    getFilters,
    getSharedOrganizationFilters,
    getUseSearchResults,
    SearchResultAsStore,
    UseSearchOptions,
    UseSearchResult,
} from '../../../utils/search'

import { useAlgoliaSearch } from './useAlgoliaSearch'
import { useOrganization } from './useOrganization'


/**
 * Full text search that returns entities based on search query.
 * @param indexName - index name that search would be performed on (see {@link ALGOLIA_INDEX_NAME})
 * @param options - pagination option
 * @param isExternalVideos - deprecated - if true, search will return external videos
 * @param filterType
 * @returns Object with keys as follows:
 * - `search` - Callable function that performs search with given query.
 * - `pagination` - Paginated search results.
 * - `isLoading`: True if search is in progress.
 * - `error`: Current error state.
 * - `lastQuery`: String with previous query.
 */
export const useIndexedSearch = <T extends ALGOLIA_INDEX_NAME>(
    indexName: T,
    options?: UseSearchOptions,
    isExternalVideos = false,
    filterType?: SearchFilterType, // TODO: Remove isExternalVideos with filterType='external'
): UseSearchResult<SearchResultAsStore<T>> => {
    const {
        limit,
        minQueryLength,
        emptyQuery,
        defaultQuery,
    } = { ...DEFAULT_SEARCH_OPTIONS, ...options }

    const { organization } = useOrganization()
    const { search: algoliaSearch, clearCache } = useAlgoliaSearch(indexName)
    // Internal state (needed for fetchMore calls)
    const [queryState, setQueryState] = useState<{ query: string, page: number }>({
        query: defaultQuery || '',
        page: 0,
    })
    // External state
    const [pagination, setPagination] = useState<PaginationInterface<SearchResultAsStore<T>> | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const fetchMore = () => {
        setQueryState(prev => ({ ...prev, page: prev.page + 1 }))
    }

    const fetchResults = useCallback(async () => {
        if (!organization) {
            // TODO should this be an error?
            setError(new Error('Organization is not loaded yet.'))
            return
        }

        setError(null)
        const { query, page } = queryState

        if (!emptyQuery && (!query || query.length < minQueryLength)) {
            setPagination(null)
            return
        }

        // TODO: Remove when isExternalVideos is replaced everywhere.
        const backwardCompatibleIsExternalVideoFilterType: SearchFilterType = isExternalVideos ? 'external' : 'internal'
        // Get indexed object from algolia
        const response = await algoliaSearch(query, {
            filters: getFilterByFilterType(filterType ?? backwardCompatibleIsExternalVideoFilterType)(organization),
            attributesToRetrieve: [],
            hitsPerPage: limit,
            page,
        })

        // Get full document data from tivio db
        const objectIDs = response.hits.map(hit => hit.objectID)
        const results = await getUseSearchResults(objectIDs, indexName, organization)

        setPagination(prevPagination => {
            if (page === 0) {
                return { items: results, fetchMore, hasNextPage: response.nbPages > 1 }
            }

            if (prevPagination) {
                return { items: prevPagination.items.concat(results), fetchMore, hasNextPage: response.nbPages > page + 1 }
            }

            return null
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [algoliaSearch, queryState, organization, isExternalVideos])

    const search = useCallback(debounce(async (query: string, forceRefresh = false) => {
        let queryTrimmed = query.trim()

        if (queryTrimmed.length < minQueryLength) {
            queryTrimmed = '' // Short queries are considered empty.
        }

        if (queryTrimmed === queryState.query && !forceRefresh) {
            return // No change in new query compared to previous one.
        }

        if (forceRefresh) {
            clearCache()
        }

        setPagination(null)
        setQueryState({ query: queryTrimmed, page: 0 }) // Reset pagination on new query.
    }, 500), [queryState.query])

    useEffect(() => {
        setIsLoading(true)
        fetchResults()
            .catch(reason => {
                // TODO reason is any
                setError(reason)
                setPagination(null)
            })
            .finally(() => setIsLoading(false))
    }, [fetchResults])

    return {
        search,
        pagination,
        // TODO PaginationInterface.loading should be used instead
        isLoading,
        error,
        // TODO mb remove this, and do this in components
        isEmptyResult: !isLoading && !!pagination?.items && pagination.items.length === 0,
        // Value of last query string
        lastQuery: queryState.query,
    }
}

export type SearchFilterType = 'external' | 'combined' | 'internal' | 'epg'

function getFilterByFilterType(filterType: SearchFilterType) {
    switch (filterType) {
        case 'internal':
        default:
            return getFilters
        case 'external':
            return getSharedOrganizationFilters
        case 'combined':
            return getExternalAndOrganizationFilters
        case 'epg':
            return getEpgAccessTagFilters
    }
}
