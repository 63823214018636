import { Container } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Article } from '../../store/Article'
import { Header } from '../common'
import { ContentAvailability } from '../content/ContentAvailability'
import { ContentDistributions } from '../content/ContentDistributions'
import { ContentOtherSettings } from '../content/ContentOtherSettings'
import { ContentPublishStatus } from '../content/ContentPublishStatus'
import { ContentTags } from '../content/ContentTags'


interface Props {
    article: Article
}

export const ArticleSettings = observer(({ article }: Props) => {
    const [t] = useTranslation()

    return (
        <Container maxWidth="lg">
            <ContentTags
                content={article}
                disabled={false}
            />
            <ContentDistributions content={article} />
            <Header title={t('Visibility')} />
            <ContentPublishStatus
                content={article}
                disabled={false}
            />
            <ContentAvailability
                showManualControl={false}
                content={article}
            />
            <ContentOtherSettings
                content={article}
                disabled={false}
            />
        </Container>
    )
})
