import { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Logger from '../../../logger'
import { AssetPreset } from '../../../store/AssetPreset'
import { getImageExtension, getImageResolution } from '../../../utils/image.utils'
import { resizeImage, uploadImage } from '../../asset/asset.utils'
import { ProgressEvent } from '../../asset/assetImageInput'
import { AssetUploaderItem } from '../../asset/assetUploader'

import { useOrganization } from './useOrganization'


type Props = {
    preset: AssetPreset
    item: AssetUploaderItem
    onProgress?: (event: ProgressEvent) => unknown
    onUploadProgress?: (progress: number) => void
}

const logger = new Logger('AssetImageInput')

const useAssetResize = (props: Props) => {
    const organizationId = useOrganization().organization?.id
    const { item, onProgress, onUploadProgress, preset } = props
    const [t] = useTranslation()

    const resizeAsset = useCallback(async (file: File) => {
        const fileExt = await getImageExtension(file)
        const filePath = item.getTempFilePath(preset, fileExt)
        const documentPath = item.getDocumentPath()
        const keepOriginal = item.keepOriginal
        const quality = item.quality || 75

        if (!organizationId) {
            throw new Error('not organization id')
        }

        try {
            onProgress?.({ state: 'uploading' })

            await uploadImage(file, filePath, onUploadProgress)

            const assetSize = await getImageResolution(file) // original image size
            const scaling = preset.getPossibleScalesToResize(assetSize) // possible scales to resize to

            onProgress?.({ state: 'resizing', message: t('Generating {{count}} qualities', { count: scaling.length }) + '...' })

            const uris = await resizeImage(
                filePath,
                documentPath,
                preset,
                organizationId,
                scaling,
                preset.mode,
                fileExt,
                quality,
                keepOriginal,
            )

            onProgress?.({ state: 'success' })

            // Also save video as video.cover for backward compatibility
            // for apps that expect video.cover
            // TODO TIV-515 remove this code once backward compatibility is not needed anymore
            const cover = uris.find(uri => uri.scale === '@1')?.url

            if (cover) {
                item.updateItem?.(uris, preset.name)
            }
        }
        catch (err) {
            logger.error(`Failed to upload to storage or resize image ${filePath}. Error`, err)

            onProgress?.({ state: 'error', error: err.message ?? 'Unknown error' })
        }
    }, [preset, organizationId])

    return {
        resizeAsset,
    }
}

export {
    useAssetResize,
}
