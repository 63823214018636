import { VideoType } from '@tivio/types'

import Video from '../../../store/Video'
import { useMember } from '../dataHooks/useMember'

import { useIsSuperAdmin } from './permissions'


export const useVideoPermissions = (video: Video, isOrganizationOwner: boolean) => {
    const { member } = useMember()
    const isSuperAdmin = useIsSuperAdmin()

    const isDisabled = !isOrganizationOwner || video.getType === VideoType.VIRTUAL_PROGRAM

    const videoOrgRef = video.data.organizationRef
    const canEditVirtualProgram = isSuperAdmin || (videoOrgRef && member?.getOrganizations.some(({ id }) => id === videoOrgRef.id))

    return {
        isDisabled,
        canEditVirtualProgram,
    }
}
