import { readFileAsDataUri } from './file.utils'


export interface Size {
  width: number;
  height: number;
}

export type ResizeImageType = 'png' | 'jpeg';

export const loadImage = (uri: string): Promise<HTMLImageElement> => {
    const image = new Image()

    return new Promise((resolve) => {
        image.onload = () => {
            resolve(image)
        }

        image.onerror = (error) => {
            throw error
        }

        image.src = uri
    })
}

export const isAtLeastMinResolution = async (
    file: File,
    minResolution: Size,
) => {
    const dataUri = await readFileAsDataUri(file)
    const image = await loadImage(dataUri)

    if (
        image.width < minResolution.width || image.height < minResolution.height
    ) {
        return false
    }

    return true
}

/**
 * Get image resolution (width and height in pixels)
 */
export const getImageResolution = async (file: File): Promise<Size> => {
    const dataUri = await readFileAsDataUri(file)
    const image = await loadImage(dataUri)

    return { width: image.width, height: image.height }
}

/**
 * Returns TRUE if size1 is smaller then size 2, otherwise FALSE
 */
export const isSmaller = (size1: Size, size2: Size): boolean => {
    return size1.width < size2.width || size2.height < size2.height
}

/**
 * Returns TRUE if size1 is greater or equal then size 2, otherwise FALSE
 */
export const isGreaterOrEqual = (size1: Size, size2: Size): boolean => {
    return !isSmaller(size1, size2)
}

const readBuffer = (file: File, start = 0, end = 2) => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(reader.result as ArrayBuffer)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(file.slice(start, end))
    })
}

/**
 * Returns asset resize supported extension based on first bytes of an image file
 * @param file Image File
 */
export const getImageExtension = async (file: File): Promise<ResizeImageType> => {
    if (file.type === 'image/svg+xml') {
        return 'png'
    }

    const buffers = await readBuffer(file, 0, 4)
    const bytes = new Uint8Array(buffers)
    const header = bytes.reduce((acc, byte) => acc + byte.toString(16), '')

    switch (header) {
        case '89504e47': // png
        case '52494646': // webp
            return 'png'
        case 'ffd8ffe0': // jpeg bytes
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffdb':
        default:
            return 'jpeg'
    }
}
