import { createLogger } from '@tivio/common'
import { ARTICLE_COVER_IMAGE } from '@tivio/firebase'
import { throttle } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import { getAsset } from '../components/asset/asset.utils'
import { toggleDistribution } from '../firebase/firestore/content'
import { getTagByPath } from '../firebase/firestore/tag'

import { alertError } from '../utils/alert.utils'
import { notEmptyFilter } from '../utils/array.utils'
import { getTranslation, translate } from '../utils/translate.utils'

import { ArticleBlock } from './ArticleBlock'
import { ContentBase } from './content/ContentBase'

import type { AssetResizeResult } from '../firebase/firestore/types'
import type { ContentInterface } from '../types/content'
import type Organization from './Organization'
import type { Tag } from './Tag'
import type { ArticleDocument } from '@tivio/firebase'
import type { ArticleBlockType, PublishedStatus, ScalableAsset, Scale } from '@tivio/types'
import type firebase from 'firebase/app'


export class Article extends ContentBase<ArticleDocument> implements ContentInterface {
    private _blocks: ArticleBlock[] = []
    private _fixedBlocks: ArticleBlock[] = []
    private _tags: Tag[] = []

    constructor(
        protected _ref: firebase.firestore.DocumentReference<ArticleDocument>,
        protected data: ArticleDocument,
        private _organization: Organization,
        inflateContent = true,
    ) {
        super(_ref, data, createLogger('Article'))

        if (inflateContent) {
            this.inflate()
        }

        this.initTags()

        makeObservable<this, '_blocks' | '_fixedBlocks' | '_tags'>(this, {
            _blocks: observable,
            _fixedBlocks: observable,
            _tags: observable,
            getAssets: computed,
            feedVisible: computed,
            getPublishedStatus: computed,
            tagRefs: computed,
            tags: computed,
            blocks: computed,
            description: computed,
            cover: computed,
            getRef: computed,
            id: computed,
            name: computed,
            getName: computed,
            landscape: computed,
            getDocumentPath: computed,
            getAsset: action,
            save: action,
            saveThrottle: action,
            addBlockAtIndex: action,
            removeBlockAtIndex: action,
            inflate: action,
            updateAsset: action,
            switchBlocks: action,
            delete: action,
            initTags: action,
            hasDistribution: action,
            updateTags: action,
            setIsFeedVisible: action,
            toggleDistribution: action,
            updatePublishedStatus: action,
            shownInRss: computed,
        })
    }

    // TODO: set visible flag based on arg
    save = async (publish?: boolean) => {
        try {
            const newData = {
                assets: this.data.assets,
                name: this._fixedBlocks.find((block) =>
                    block.type === 'HEADING',
                )?.textContent ?? {},
                description: this._fixedBlocks.find((block) =>
                    block.type === 'PARAGRAPH',
                )?.htmlContent ?? {},
                blocks: this._blocks.map((block) => block.data),
            }

            await this.update(newData)
        } catch (e) {
            alertError(translate('Failed to save article'))
            console.error(e)
        }
    }

    saveThrottle = throttle(this.save, 1000)

    addBlockAtIndex = (type: ArticleBlockType, index: number) => {
        this._blocks.splice(
            index,
            0,
            new ArticleBlock({ id: nanoid(), type }, this),
        )

        this.save()
    }

    removeBlockAtIndex = (index: number) => {
        this._blocks.splice(index, 1)

        this.save()
    }

    inflate() {
        this._fixedBlocks = [
            new ArticleBlock({
                id: nanoid(),
                type: 'IMAGE',
                imageUrl: this.cover ?? undefined,
            }, this),
            new ArticleBlock({
                id: nanoid(),
                type: 'HEADING',
                textContent: this.name,
            }, this),
            new ArticleBlock({
                id: nanoid(),
                type: 'PARAGRAPH',
                htmlContent: this.description,
            }, this),
        ]

        this._blocks = this.data.blocks.map((block) =>
            new ArticleBlock(block, this),
        )
    }

    get getAssets() {
        return this.data.assets
    }

    getAsset = (presetName: string, scale?: Scale) => {
        return getAsset(
            this.getAssets ?? {},
            presetName,
            scale,
        )
    }

    updateAsset = async (resizeResults: AssetResizeResult[], name: string) => {
        const scalableAsset = resizeResults.reduce(
            (resultObject, resizeResult) => ({
                ...resultObject,
                [resizeResult.scale]: {
                    background: resizeResult.url,
                },
            }),
            {},
        ) as ScalableAsset

        this.data.assets = { ...this.data.assets, [name]: scalableAsset }

        await this.save()
    }

    switchBlocks = (index1: number, index2: number) => {
        if (index1 === index2) {
            return
        }

        const [removed] = this._blocks.splice(index1, 1)
        this._blocks.splice(index2, 0, removed)

        this.save()
    }

    /**
   * Removes video from DB.
   */
    delete = async () => {
        await this.ref.delete()
    }


    /**
   * Prepare all tags for this video.
   */
    initTags = async () => {
        try {
            const tagsDb = this.tagRefs && await Promise.all(this.tagRefs
                .map(async (tagRef) => await getTagByPath(tagRef.path)))

            this.tags = tagsDb?.filter(notEmptyFilter) ?? [] as Tag[]
        } catch (e) {
            this.logger.error(`Failed to load content tags: ${this.ref.path}`, e)
        }
    }

    hasDistribution = (tag: Tag) => {
        return this.tags.some((t) => t.tagId === tag.tagId)
    }

    updateTags = async (tags: Tag[]) => {
        this.tags = tags
        await this.update({
            tags: tags.map((tag) => tag.getRef),
        })
    }

    setIsFeedVisible = async (isActive: boolean) => {
        await this.update({
            feedVisible: isActive,
        })
    }

    toggleDistribution = async (tag: Tag, isActive: boolean) => {
        await toggleDistribution(this, this._organization, tag, isActive)
    }

    updatePublishedStatus = async (publishedStatus: PublishedStatus) => {
        await this.update({
            publishedStatus,
        })
    }

    async setShowInRss() {
        alertError(translate('RSS functionality is not available for articles yet'))
    }

    get feedVisible() {
        return !!this.data.feedVisible
    }

    get getPublishedStatus() {
        return this.data.publishedStatus
    }

    get tagRefs() {
        return this.data.tags
    }

    get tags() {
        return this._tags
    }

    set tags(tags: Tag[]) {
        this._tags = tags
    }

    get blocks() {
        return [...this._fixedBlocks, ...this._blocks]
    }

    get description() {
        return this.data.description
    }

    get cover() {
        return this.getAsset(ARTICLE_COVER_IMAGE)
    }

    get ref() {
        return this._ref
    }

    get getRef() {
        return this._ref
    }

    get id() {
        return this._ref.id
    }

    get name() {
        return this.data.name
    }

    get getName() {
        return getTranslation(this.data.name, this._organization.languages)
    }

    get landscape() {
        return this.cover
    }

    get getDocumentPath() {
        return this._ref.path
    }

    get shownInRss() {
        return false
    }
}
