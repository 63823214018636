import {
    TAG_BANNER_ASSET_NAME,
    TAG_COVER_ASSET_NAME,
    TAG_LANDSCAPE_COVER_ASSET_NAME,
    TAG_PORTRAIT_COVER_ASSET_NAME,
    TAG_SQUARE_COVER_ASSET_NAME,
    type TagDocument,
} from '@tivio/firebase'
import { ROW_ITEM_TYPES, TagSimplicityType } from '@tivio/types'
import { makeAutoObservable } from 'mobx'

import { getAsset } from '../components/asset/asset.utils'
import { AssetResizeResult } from '../firebase/firestore/types'

import type { AssetsField, ScalableAsset, Scale } from '@tivio/types'
import type firebase from 'firebase/app'


class Tag {
    constructor(
        private ref: firebase.firestore.DocumentReference<TagDocument>,
        private data: TagDocument,
    ) {

        makeAutoObservable(this)
    }

    updateTag = async (data: Partial<TagDocument>) => {
        await this.ref.update(data)
        this.data = {
            ...this.data,
            ...data,
        }
    }

    /**
     * Update tag without merge values
     */
    setTag = async (data: Omit<TagDocument, 'created'>) => {
        const tagData = { ...data, created: this.data.created }
        await this.ref.set(tagData)
        this.data = { ...tagData }
    }

    getAsset = (presetName: string, scale?: Scale) => {
        return getAsset(
            this.assets ?? {},
            presetName,
            scale,
        )
    }

    get getRef() {
        return this.ref
    }

    get assets() {
        return this.data.assets
    }

    get getNameTranslation() {
        return this.data.name
    }

    get id() {
        return this.ref.id
    }

    get name() {
        return this.data.name
    }

    get tagId() {
        return this.data.tagId
    }

    get tagTypeRef() {
        return this.data.tagTypeRef
    }

    private getCoverAsset() {
        const assets = Object.values(this.assets ?? {}) as any[]
        const asset = assets?.[0]

        const scaledAsset = (
            asset?.['@1'] ??
            asset?.['@2'] ??
            asset?.['@3']
        )

        return scaledAsset?.background ?? null
    }

    get getCover() {
        return this.getCoverAsset() ?? ''
    }


    get cover(): string | null {
        return this.getAssetBackground(TAG_COVER_ASSET_NAME, '@1')
    }

    get banner() {
        return this.getAssetBackground(TAG_BANNER_ASSET_NAME, '@1')
    }

    get landscape() {
        return this.getAssetBackground(TAG_LANDSCAPE_COVER_ASSET_NAME, '@1')
    }

    get portrait() {
        return this.getAssetBackground(TAG_PORTRAIT_COVER_ASSET_NAME, '@1')
    }

    get circled() {
        return this.getAssetBackground(TAG_COVER_ASSET_NAME, '@1')
    }

    get square() {
        return this.getAssetBackground(TAG_SQUARE_COVER_ASSET_NAME, '@1')
    }

    private getAssetBackground(assetName: string, scale: Scale) {
        return this.data.assets?.[assetName]?.[scale]?.background ?? null
    }

    get getName() {
        return this.data.name.en
    }

    get description() {
        return this.data.description
    }

    set setAssets(assets: AssetsField) {
        this.data.assets = assets
    }

    get itemType() {
        return ROW_ITEM_TYPES.TAG
    }

    get created() {
        return this.data.created
    }

    get metadata() {
        return this.data.metadata ?? []
    }

    get simplicity() {
        return this.data.simplicity ?? TagSimplicityType.SIMPLE
    }

    get composed() {
        return this.simplicity === TagSimplicityType.COMPOSED
    }

    get simpleTagsRefs(): firebase.firestore.DocumentReference<TagDocument>[] {
        return this.data.simpleTagsRefs ?? []
    }

    saveAssetResizeResults(resizeResults: AssetResizeResult[], name: string) {
        // we always expect at least '@1' scale here
        const scalableAsset = resizeResults.reduce((resultObject, resizeResult) => ({
            ...resultObject,
            [resizeResult.scale]: {
                background: resizeResult.url,
            },
        }), {}) as ScalableAsset

        this.setAssets = { ...this.assets, [name]: scalableAsset }
    }

    get getDocumentPath() {
        return this.ref.path
    }
}

export {
    Tag,
}
