import noop from 'lodash/noop'
import { useCallback, useState } from 'react'

import { readFileAsDataUri } from '../../../utils/file.utils'

import { useConfirmAction } from './useConfirmAction'

import type { FileValidationResult } from './useValidateFile'


interface Props {
    image?: string | null
    validateFile?: (file: File) => Promise<FileValidationResult>
    onFile?: (file: File) => void
}

export const useImageUpload = (props: Props) => {
    const { validateFile, onFile } = props
    // TODO TIV-546 always when props.image changes use it's latest value
    const [image, setImage] = useState<string | null>(props.image ?? null)
    const [error, setError] = useState<Error | null>(null)

    // TODO TIV-546 should be error, not confirm action
    const { confirmAction } = useConfirmAction()

    const onFiles = useCallback((files: File[]) => {
        const acceptFile = async (file: File) => {
            onFile?.(file)

            const imageDataUri = await readFileAsDataUri(file)
            setImage(imageDataUri)
        }

        const processFile = async () => {
            try {
                const file = files[0]
                const fileValidationResult = await validateFile?.(file)
                const fileValidationErrors = fileValidationResult?.errors ?? []
                const fileValidationWarnings = fileValidationResult?.warnings ?? []

                if (fileValidationErrors.length !== 0) {
                    // error message, file won't be uploaded
                    confirmAction(noop, fileValidationErrors.join(', '))
                } else if (fileValidationWarnings.length !== 0) {
                    // user can choose if wants to upload or not
                    confirmAction(
                        () => acceptFile(file),
                        fileValidationWarnings.join(', '),
                    )
                } else {
                    await acceptFile(file)
                }
            } catch (error) {
                setError(error)
            }
        }

        processFile()
    }, [onFile, validateFile])

    return {
        image,
        error,
        onFiles,
    }
}
