import { Logger } from '@tivio/common'
import { InviteState, InviteType, OrganizationDocument, OrganizationMemberRoles, VideoDocument } from '@tivio/firebase'
import { VideoType } from '@tivio/types'
import firebase from 'firebase'

import { createSectionPlaylist, createSectionVideo } from '../../creator/video.creator'
import Channel from '../../store/Channel'
import Invite from '../../store/Invite'
import Member from '../../store/Member'
import MemberAdmin from '../../store/MemberAdmin'
import { Membership } from '../../store/Membership'
import Organization from '../../store/Organization'
import OrganizationAdmin from '../../store/OrganizationAdmin'
import Section from '../../store/Section'
import User from '../../store/User'
import Video from '../../store/Video'
import Widget from '../../store/Widget'
import { notEmptyFilter } from '../../utils/array.utils'
import { getFirestore, loggerFirestore } from '../app'

import { listenAssetPresets } from './assetPresets'
import { organizationConverter, userConverter } from './converters'
import { getInvitesCollection } from './invite'
import { getMemberships } from './membership'
import { listenMonetizations } from './monetization'
import { getSectionsCollection } from './section'
import { listenTagTypes } from './tagType'
import { listenTvChannels } from './tvChannels'

import type { TMemberFirestore } from './member'


const logger = new Logger('Organization')

export interface TOrganizationMember {
    [uid: string]: {
        memberRef: firebase.firestore.DocumentReference<TMemberFirestore>
        role: OrganizationMemberRoles
    }
}

export const DEFAULT_VOD_CHANNELS_LIMIT = 30

export const getOrganizationsCollection = () => {
    return getFirestore().collection('organizations').withConverter(organizationConverter)
}

export const getOrganizationRef = (organizationId: string) => {
    return getOrganizationsCollection().doc(organizationId)
}

const getUsersCollection = () => getFirestore().collection('users').withConverter(userConverter)

const handleCreateSectionElement = async (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    section: Section,
    organization: Organization,
) => {
    if (data.type === VideoType.VIDEO) {
        return createSectionVideo(
            ref as firebase.firestore.DocumentReference<VideoDocument>,
            data as VideoDocument,
            section,
            organization,
        )
    }

    const videos: Video[] = []

    const playlist = createSectionPlaylist(
        ref as firebase.firestore.DocumentReference<VideoDocument>,
        data as VideoDocument,
        section,
        organization,
    )

    await Promise.all(
        (data as VideoDocument).videos!.map( // Videos must exist, because video is playlist.
            async videoRef => {
                const videoSnapshot = await videoRef.get()
                const videoData = videoSnapshot.data() as VideoDocument

                if (videoData) {
                    const video = createSectionVideo(
                        videoSnapshot.ref,
                        videoData,
                        section,
                        organization,
                        playlist,
                    )

                    videos.push(video)
                }
            },
        ),
    )

    playlist.setVideos = videos

    return playlist
}

export const initChannel = async (channel: Channel) => {
    const sectionsCollection = await getSectionsCollection(channel.getId).get()

    const sections = await Promise.all(
        sectionsCollection.docs.map(
            async sectionSnapshot => {
                const sectionData = sectionSnapshot.data()
                const section = new Section(sectionSnapshot.ref, sectionData, channel)

                const elementsRefs = section.getElementsRefs
                const elements = await Promise.all(elementsRefs.map(
                    async elementRef => {

                        const elementSnapshot = await elementRef.get()
                        const elementData = elementSnapshot.data()

                        if (elementData) {
                            return await handleCreateSectionElement(elementRef, elementData, section, channel.getOrganization)
                        }
                    }))

                section.setElements = elements.filter(notEmptyFilter)
                return section
            },
        ),
    )

    channel.setSections = sections.filter(notEmptyFilter)
    channel.setIsInitialized = true
}

const startListeningToTvChannels = async (organization: Organization) => {
    const disposer = await listenTvChannels(organization, tvChannels => {
        organization.tvChannels = tvChannels
    })

    organization.addDisposer(disposer)
}

const startListeningToAssetPresets = (organization: Organization) => {
    const disposer = listenAssetPresets(organization, presets => {
        organization.assetPresets = presets
    })

    organization.addDisposer(disposer)
}

const startListeningToTagTypes = (organization: Organization) => {
    const disposer = listenTagTypes(organization.id, tagTypes => {
        organization.tagTypes = tagTypes
    })

    organization.addDisposer(disposer)
}

const startListeningToMonetizations = (organization: Organization) => {
    const disposer = listenMonetizations(organization, monetizations => {
        organization.monetizations = monetizations
    })

    organization.addDisposer(disposer)
}

export const initOrganization = async (organization: Organization, memberUid: string) => {
    logger.info(`Initializing organization (id: ${organization.id}, name: ${organization.name})...`)

    try {
        startListeningToTagTypes(organization)

        await startListeningToTvChannels(organization)

        const widgetsRefs = organization.widgetsRefs ?? []
        const widgets = await Promise.all(
            widgetsRefs.map(
                async widgetRef => {
                    const widgetSnapshot = await widgetRef.get()
                    const widgetData = widgetSnapshot.data()

                    if (widgetData) {
                        const widget = new Widget(widgetRef, widgetData, organization)

                        const channelsRefs = widget.getChannelsOrder
                        const channels = await Promise.all(
                            channelsRefs.map(
                                async channelRef => {
                                    const channelSnapshot = await channelRef.get()
                                    const channelData = channelSnapshot.data()

                                    if (channelData) {
                                        return new Channel(channelRef, channelData, organization)
                                    }
                                },
                            ),
                        )

                        widget.setChannels = channels.filter(notEmptyFilter)

                        return widget
                    }
                },
            ),
        )

        const membershipSnapshots = await getMemberships(organization).orderBy('created').get()
        const memberships = membershipSnapshots.docs.map(
            (m) => new Membership(m.ref, m.data()),
        )

        startListeningToMonetizations(organization)
        startListeningToAssetPresets(organization)

        organization.widgets = widgets.filter(notEmptyFilter)
        organization.memberships = memberships

        await organization.initOrganizationConsents(memberUid)
        await organization.initOrganizationEpgChannelAccessTag()
        organization.initOrganizationChannelsPagination()
        await organization.initAllChannelsPagination()
        organization.initArticlesPagination()
        organization.initPostsPagination()
        organization.initOrganizationEmailTemplates()

        return organization
    } catch (e) {
        loggerFirestore.error(e)
        throw new Error(e)
    }
}

export const updateOrganization = async (
    organization: Organization | OrganizationAdmin,
    organizationData: Partial<OrganizationDocument>,
) => {
    try {
        await getOrganizationsCollection()
            .doc(organization.id)
            .update(organizationData)

        loggerFirestore.info('Organization updated')
    } catch (e) {
        loggerFirestore.error('Failed to update organization', e)
        throw new Error(e)
    }
}

const handleOrganizationSnapshot = async (
    organizationsSnapshot:
        firebase.firestore.QueryDocumentSnapshot<OrganizationDocument> |
        firebase.firestore.DocumentSnapshot<OrganizationDocument>,
    currentMember: Member,
) => {
    const organizationData = organizationsSnapshot.data()
    const organizationRef = organizationsSnapshot.ref

    if (organizationData) {
        const invitesSnapshots = await getInvitesCollection()
            .where('organizationRef', '==', organizationRef)
            .where('state', '!=', InviteState.CLOSED)
            .where('type', '==', InviteType.ADMIN)
            .get()

        const invites = await Promise.all(
            invitesSnapshots.docs.map(inviteSnapshot => {
                const inviteData = inviteSnapshot.data()

                if (inviteData) {
                    return new Invite(inviteSnapshot.ref, inviteData)
                }
            }),
        )

        const membersUids = Object.keys(organizationData.members)
        const members = await Promise.all(
            membersUids.map(
                async uid => {
                    const memberRef = organizationData.members[uid].memberRef

                    if (memberRef.id === currentMember.getId) {
                        return
                    }

                    const memberSnapshot = await memberRef.get()
                    const memberData = memberSnapshot.data()

                    if (memberData) {
                        const organizationMember = organizationData.members[memberData.uid]
                        return new MemberAdmin(memberRef, memberData, organizationMember.role)
                    }
                }),
        )

        return new OrganizationAdmin(
            organizationRef,
            organizationData,
            members.filter(notEmptyFilter),
            invites.filter(notEmptyFilter),
        )
    }
}

export const listOrganizations = async (currentMember: Member) => {
    try {
        const organizationsCollection = getOrganizationsCollection()
        const organizationsSnapshots = await organizationsCollection.get()

        const organizationsAdmin = await Promise.all(
            organizationsSnapshots.docs.map(
                organizationsSnapshot => handleOrganizationSnapshot(organizationsSnapshot, currentMember),
            ),
        )

        return organizationsAdmin.filter(notEmptyFilter)
    } catch (e) {
        loggerFirestore.error('Failed to fetch organizations', e)
        throw new Error(e)
    }
}

export const getOrganizationAdmin = async (organization: Organization, member: Member) => {
    try {
        const organizationSnapshot = await getOrganizationsCollection().doc(organization.id).get()
        const organizationAdmin = await handleOrganizationSnapshot(organizationSnapshot, member)

        return [organizationAdmin].filter(notEmptyFilter)
    } catch (e) {
        throw new Error(e)
    }
}

export const addOrganization = async (organizationData: OrganizationDocument) => {
    try {
        const organizationRef = await getOrganizationsCollection().add(organizationData)

        loggerFirestore.info(`Organization created with id: ${organizationRef.id}`)

        return organizationRef
    } catch (e) {
        loggerFirestore.error('Failed to create organization', e)
        throw new Error(e)
    }
}

export const getOrganizationsByUid = async (uid: string) => {
    try {
        const organizationsSnapshots = await getOrganizationsCollection().where(`members.${uid}`, '!=', null).get()

        if (organizationsSnapshots.empty) {
            throw new Error('Member does not belongs to any organization!')
        }

        const organizations = await Promise.all(
            organizationsSnapshots.docs.map(
                async organizationSnapshot => {
                    const organizationData = organizationSnapshot.data()

                    if (organizationData) {
                        return new Organization(organizationSnapshot.ref, organizationData)
                    }
                },
            ),
        )

        return organizations.filter(notEmptyFilter)
    } catch (e) {
        throw new Error(e)
    }
}

export const addSecretRefs = async (organizationId: string, secretIds: string[]): Promise<void> => {
    try {
        const collection = getFirestore().collection('organizations')
        await collection.doc(organizationId)
            .update({ secrets: firebase.firestore.FieldValue.arrayUnion.apply(this, secretIds) })
        loggerFirestore.info('Secret ID has been added')
    } catch (e) {
        loggerFirestore.error('Failed to add Secret ID', e)
        throw new Error(e)
    }
}

export const removeSecretRef = async (organizationId: string, secretIds: string[]): Promise<void> => {
    try {
        const collection = getFirestore().collection('organizations')
        await collection.doc(organizationId)
            .update({ secrets: firebase.firestore.FieldValue.arrayRemove.apply(this, secretIds) })
        loggerFirestore.info('Secret ID has been removed', secretIds)
    } catch (e) {
        loggerFirestore.error('Failed to remove Secret ID', secretIds, e)
        throw new Error(e)
    }
}

export const loadUsers = async (organization: Organization, email?: string) => {
    loggerFirestore.info('Loading users')

    try {
        let query = getUsersCollection()
            .where('organizationRef', '==', organization.ref)

        if (email) {
            // copied from https://stackoverflow.com/a/57290806/19853762
            const end = email?.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1))

            query = query
                .where('email', '>=', email)
                .where('email', '<', end)
        }

        const snapshot = await query
            .limit(30)
            .get()

        if (snapshot.empty) {
            loggerFirestore.info('No users under this organization')
            return
        }

        const allUsers = await Promise.all(snapshot.docs.map(
            async userSnapshot => {
                const userData = userSnapshot.data()

                if (userData) {
                    return new User(userSnapshot.ref, userData, organization)
                }
            },
        ))

        return allUsers.filter(notEmptyFilter)
    } catch (e) {
        loggerFirestore.error('Failed to load users', e)
        throw new Error(e)
    }
}

/**
 * Loads user by ID and checks if he belongs to the organization.
 *
 * @param userId User ID.
 * @param organization Organization store to which user belongs.
 * @returns User or undefined if user doesn't exist.
 */
export const loadUser = async (userId: string, organization: Organization): Promise<User | undefined> => {
    loggerFirestore.info('Loading user', userId)

    try {
        const snapshot = await getUsersCollection()
            .doc(userId)
            .get()

        if (!snapshot.exists) {
            loggerFirestore.info('No user found', userId)
            return
        }

        const userData = snapshot.data()! // it exists

        if (userData.organizationRef.id !== organization.ref.id) {
            loggerFirestore.info('No user found', organization.ref.id, userId)
            return
        }

        return new User(snapshot.ref, userData, organization)
    } catch (e) {
        loggerFirestore.error('Failed to load user', e)
        throw new Error(e)
    }
}

export const updateMemberRole = async (organization: Organization | OrganizationAdmin, member: MemberAdmin, role: OrganizationMemberRoles) => {
    try {
        const organizationMemberUpdate = { [`members.${member.getUid}.role`]: role }
        await getOrganizationsCollection()
            .doc(`${organization.id}`).update(organizationMemberUpdate)

        loggerFirestore.info('Organization member role updated')
    } catch (e) {
        loggerFirestore.error('Failed to update organization member role:', e)
        throw new Error(e)
    }
}
